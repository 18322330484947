import { useState, useEffect, useRef } from "react";
import { ChevronDown, ChevronUp, Search, X, EmojiFrown } from "react-bootstrap-icons";
import propTypes from "prop-types";
import {
  chatTrialAssociatedMembers,
  chatSearchUserApi,
  teamsChatCreate,
  sendWelcomeMessage
} from "../services/API";
import { Spinner } from "../services/Common";
import "../assests/Styles/newviewer.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCookies } from "react-cookie";

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export default function ChatList({
  trail,
  // setModalJoinTeam,
  addPeople,
  setNewGroupCreated,
  setSelectedPeopleUserId,
  selectedGroupNameWhileAdding,
  groupsList,
  groupLists,
  combinedText,
  selectedFlightDate,
  customMobilePortrait,
  customMobileLandscape,
  setShowChatDiv,
  setShowNewDiv,
  setNewChat,
  setDisableAddPeople
}) {
  const [openState, setOpenState] = useState({});
  const [checkedState, setCheckedState] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [showClearIcon, setShowClearIcon] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [differentGroups, setDifferentGroups] = useState([]);
  const [isGeneralUsers, setIsGeneralUsers] = useState(false);
  const [cookies] = useCookies(["accessToken", "userEmail"]);
  const accessToken = cookies?.accessToken;
  const userId = sessionStorage.getItem("userId");
  const currentUserEmail = cookies?.userEmail;
  const [joinTeamsClick, setJoinTeamsClick] = useState(false);

  useEffect(() => {
    if(addPeople && Object.keys(checkedState)?.length > 0) {
      setDisableAddPeople(Object.values(checkedState).every((item) => item === false));
    }
  }, [addPeople, checkedState])

  useEffect(() => {
    const payload = {
      access_token: accessToken,
      trial_name: trail
    };
    chatTrialAssociatedMembers(
      payload,
      (res) => {
        setLoading(false);
        let processedData = [
          {
            id: trail,
            name: trail,
            children: Object.entries(res)
              ?.map(
                ([key, value]) =>
                  value?.length > 0 && {
                    id: key,
                    name: key
                      .split("_")
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(" "),
                    children: value.map((item) => ({
                      id: item.id,
                      name: item.mail
                    }))
                  }
              )
              .filter(Boolean)
          }
        ];

        if (addPeople && groupsList?.length > 0 && processedData[0]?.children?.length > 0) {
          const newfilterGroupList = groupsList.filter(
            (group) => group?.name === selectedGroupNameWhileAdding
          );
          processedData[0]?.children.forEach((item, index) => {
            const categoryExist = newfilterGroupList[0]?.children?.find(
              ({ name }) => name === item?.name
            );
            if (categoryExist) {
              processedData[0].children[index].children = item.children.map((child) => {
                const memberExist = categoryExist?.children.find(
                  ({ name }) => name === child?.name
                );
                if (memberExist) {
                  return {
                    ...child,
                    chatGroupId: memberExist?.chatGroupId
                  };
                } else {
                  return child;
                }
              });
            }
          });
          const othersAvailable = newfilterGroupList[0]?.children?.find(
            ({ name }) => name === "Others"
          );
          if (othersAvailable) {
            processedData[0].children.push(othersAvailable);
          }
        }
        setFilteredData(processedData);
        initializeStates(processedData);
      },
      (err) => {
        setLoading(false);
        console.log("err", err);
      }
    );
  }, []);

  useEffect(() => {
    if (addPeople && groupsList?.length > 0) {
      const newfilterGroupList = groupsList.filter(
        (group) => group?.name === selectedGroupNameWhileAdding
      );
      filteredData[0]?.children.map((item) => {
        if (item.name === selectedGroupNameWhileAdding) {
          const updatedCheckedState = { ...checkedState };
          newfilterGroupList[0]?.members.map((member) => {
            updatedCheckedState[member.id] = true;
          });
          setCheckedState(updatedCheckedState);
        }
      });
    }
  }, []);

  useEffect(() => {
    const parsedData = filteredData[0]?.children.map((item) => ({
      id: item.id,
      name: item?.name,
      children: item?.children?.length > 0 && item?.children.map((child) => child.name)
    }));
    setDifferentGroups(parsedData);
  }, [filteredData]);

  useEffect(() => {
    if (isGeneralUsers) {
      const generalUsersGroup = differentGroups.find((group) => group.name === "Others");
      if (generalUsersGroup && Object.keys(generalUsersGroup)?.length > 0) {
        const updatedCheckedState = { ...checkedState };
        updatedCheckedState[generalUsersGroup.id] = true;
        setCheckedState(updatedCheckedState);
        setIsGeneralUsers(false);
      }
    }
  }, [isGeneralUsers, differentGroups, checkedState]);

  const initializeStates = (data) => {
    const openState = {};
    const checkedState = {};

    const traverse = (items) => {
      items.forEach((item) => {
        openState[item.id] = true;
        openState[item.name] = true;
        checkedState[item.id] = false;
        if (item.children) {
          traverse(item.children);
        }
      });
    };

    traverse(data);
    setOpenState(openState);
    setCheckedState(checkedState);
  };

  const toggleOpen = (id) => {
    setOpenState((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleCheck = (id, isChecked) => {
    const newCheckedState = { ...checkedState };
    const updateChildren = (items) => {
      items.forEach((item) => {
        newCheckedState[item.id] = isChecked;
        if (item.children) {
          updateChildren(item.children);
        }
      });
    };

    const updateParents = (items, targetId) => {
      for (let item of items) {
        if (item.id === targetId) {
          return true;
        }
        if (item.children) {
          const found = updateParents(item.children, targetId);
          if (found) {
            newCheckedState[item.id] = item.children.every((child) => newCheckedState[child.id]);
            return true;
          }
        }
      }
      if (addPeople) {
        setSelectedPeopleUserId(item?.id);
      }
      return false;
    };

    newCheckedState[id] = isChecked;

    const item = findItem(filteredData, id);
    if (item?.children) {
      updateChildren(item.children);
    }

    updateParents(filteredData, id);

    setCheckedState(newCheckedState);
  };
  const findItem = (items, id) => {
    for (let item of items) {
      if (item.id === id) return item;
      if (item.children) {
        const found = findItem(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const countGrandchildren = (item) => {
    let count = 0;
    if (item.children) {
      item.children.forEach((child) => {
        if (child.children) {
          count += child.children.length;
        }
      });
    }
    return count;
  };

  const handleSearch = (query) => {
    if (query.length >= 3) {
      const chatSearchPayload = {
        value: query,
        access_token: accessToken
      };
      chatSearchUserApi(
        chatSearchPayload,

        (res) => {
          setSearchLoader(false);
          setSearchResults(res);
        },
        (err) => {
          setSearchLoader(false);
          console.log("err", err);
        }
      );
    }

    // setSearchQuery(e);
    setShowSearchResults(true);
    setSearchLoader(true);
    setShowClearIcon(query.trim().length > 0);
    searchResults?.results?.filter(
      (item) => item?.mail !== null && item?.mail.toLowerCase().includes(query.toLowerCase())
    );
    if (query.length === 0) {
      setShowSearchResults(false);
    }
  };

  const debouncedHandleChange = useRef(debounce(handleSearch, 500));

  const clearSearch = () => {
    setSearchQuery("");
    let data = filteredData;
    setFilteredData(data);
    setSearchResults([]);
    setShowClearIcon(false);
    setShowSearchResults(false);
  };

  const checkEmailExists = (email) => {
    return differentGroups?.filter((item) => item?.children.includes(email));
  };

  const addnewGroup = (item) => {
    setIsGeneralUsers(true);
    const generalUsersExist = filteredData[0]?.children.findIndex((item) => item.name === "Others");
    if (generalUsersExist === -1) {
      const generalUserGroup = {
        id: "Others",
        name: "Others",
        children: [{ id: item?.id, name: item?.mail }]
      };
      setFilteredData((prev) => {
        const addGeneralUsers = [...prev[0].children, generalUserGroup];
        return [
          {
            ...prev[0],
            children: addGeneralUsers
          }
        ];
      });
    } else {
      setFilteredData((prev) => {
        const addGeneralUsers = prev[0].children.map((group, index) => {
          if (index === generalUsersExist) {
            return {
              ...group,
              children: [...group.children, { id: item?.id, name: item?.mail }]
            };
          }
          return group;
        });
        const reOrdering = [
          ...addGeneralUsers.filter((group) => group.name !== "Others"),
          addGeneralUsers.find((group) => group.name === "Others")
        ];
        return [
          {
            ...prev[0],
            children: reOrdering
          }
        ];
      });
    }
    if (!openState["Others"]) {
      toggleOpen("Others");
    }
  };

  const handleSelectItem = (item) => {
    if (checkEmailExists(item?.mail)?.length > 0) {
      setCheckedState((prev) => ({ ...prev, [item.id]: true }));
      differentGroups?.forEach((group) => {
        group?.children?.forEach((child) => {
          if (item?.mail === child) {
            if (!openState[group.name]) {
              toggleOpen(group.name);
            }
          }
        });
      });
    } else {
      addnewGroup(item);
      setCheckedState((prev) => ({ ...prev, [item.id]: true }));
    }
    setSearchQuery("");
    setShowSearchResults(false);
    setJoinTeamsClick(false);
    setShowClearIcon(false);
    if (addPeople) {
      setSelectedPeopleUserId(item?.id);
    }
  };

  const isAnyCheckboxChecked = () => {
    const newData = Object.entries(checkedState).filter((item) => {
      return item[0] !== "Others" && item[0] !== trail && item[1] === true;
    });
    if (newData?.length > 1) {
      return true;
    } else if (newData?.length === 1 && newData[0][0] !== userId) {
      return true;
    } else {
      return false;
    }
  };

  const createTeamsChatOnclick = () => {
    if (!joinTeamsClick) {
      setJoinTeamsClick(true);
    }
    const userIds = Object.keys(checkedState)
      .filter((key) => checkedState[key])
      .join(",");
    const newuserIds = userIds.split(",");
    newuserIds.push(sessionStorage.getItem("userId"));
    const filterUserIds = newuserIds.filter(
      (item) =>
        item !== trail &&
        item !== "Others" &&
        item !== "trial_owner" &&
        item !== "protocol_owner" &&
        item !== "trial_manager"
    );
    const uniqueUserIds = [...new Set(filterUserIds)];

    // Check if trail is already present in groupsList as a topic
    let modifiedTrail = trail;
    let counter = 1;

    if (groupsList?.length > 0) {
      while (groupsList.some((group) => group.name === modifiedTrail)) {
        modifiedTrail = `${trail}_${counter}`;
        counter++;
      }
    }
    const payload = {
      user_email: currentUserEmail,
      trial_name: trail,
      access_token: accessToken,
      topic: modifiedTrail,
      user_ids: uniqueUserIds
    };

    teamsChatCreate(
      payload,
      (res) => {
        const payload = {
          chat_group_id: res?.id,
          access_token: accessToken,
          current_url: window.location.href,
          site_name: combinedText,
          collection_date: selectedFlightDate || ""
        };
        sendWelcomeMessage(
          payload,
          () => {
            setLoading(false);
            groupLists();
            setNewGroupCreated(true);
            window.open(res?.webUrl, "_blank");
            setShowChatDiv(false);
            setShowNewDiv(false);
            setNewChat(false);
          },
          (err) => {
            setLoading(false);
            console.log("err", err);
          }
        );
      },
      (err) => {
        setLoading(false);
        console.log("err", err);
      }
    );
  };

  // const handlemodalJoinTeamsClick = () => {
  //   setModalJoinTeam(true);
  // };

  const checkIfAllChildrenIsDefaultChecked = (id) => {
    return filteredData[0]?.children
      .find((item) => item.id === id)
      ?.children.every((child) => child?.chatGroupId);
  };
  const renderAccordion = (items, level = 0, isModal = false) => {
    if (level === 2) {
      items.sort((a, b) => a.name.localeCompare(b.name));
    }
    return items.map((item) => {
      const childCount = level === 0 ? countGrandchildren(item) : item.children;
      const grandchildCount = childCount ? item.children.length : 0;

      return (
        <div key={item.id}>
          <div
            className="d-flex justify-content-between items-center space-x-2 mb-2"
            style={{ marginLeft: customMobilePortrait && "8px" }}>
            <span>
              <span>
                <input
                  type="checkbox"
                  checked={
                    checkedState[item.id] ||
                    item?.chatGroupId ||
                    (level === 1 && addPeople && checkIfAllChildrenIsDefaultChecked(item.id))
                  }
                  onChange={(e) => handleCheck(item.id, e.target.checked)}
                  className="form-checkbox"
                  disabled={
                    item?.chatGroupId ||
                    (level === 1 && addPeople && checkIfAllChildrenIsDefaultChecked(item.id))
                  }
                  onClick={(e) => e.stopPropagation()}
                />
              </span>
              <span onClick={() => toggleOpen(item.name)}>
                <span
                  className={`cursor-pointer accordion-text-heading ${
                    level === 2 ? "not-bold-text" : "bold-text"
                  }`}>
                  {level === 0 && addPeople && selectedGroupNameWhileAdding
                    ? selectedGroupNameWhileAdding
                    : item.name}
                  {level === 0 && " : "}
                </span>
                {/* {level === 0 && (
                  <span style={{ fontSize: "12px", fontWeight: 400 }}>In-Season Insights</span>
                )} */}
                <span>
                  {grandchildCount > 0 && level === 0 && (
                    <span className="count-accordion">({grandchildCount})</span>
                  )}
                </span>
                <span>
                  {item.children && (
                    <button className="ml-auto icon-no-border">
                      {openState[item.name] ? (
                        <ChevronUp className="icon-no-border" role="button" />
                      ) : (
                        <ChevronDown className="icon-no-border" />
                      )}
                    </button>
                  )}
                </span>
              </span>
            </span>

            <span>
              {level === 0 &&
                !addPeople &&
                (isAnyCheckboxChecked() && !joinTeamsClick ? (
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => (
                      <Tooltip className="title-tooltip" {...props}>
                        Navigate to Teams app
                      </Tooltip>
                    )}>
                    <span
                      className={
                        isModal
                          ? "modal-accordion-text-teams join-teams-text"
                          : "accordion-text-teams join-teams-text"
                      }
                      style={{
                        color: "#255AF6",
                        cursor: "pointer"
                      }}
                      onClick={createTeamsChatOnclick}>
                      Join Teams
                    </span>
                  </OverlayTrigger>
                ) : (
                  <span
                    className={
                      isModal
                        ? "modal-accordion-text-teams join-teams-text disabled"
                        : "accordion-text-teams join-teams-text disabled"
                    }
                    style={{
                      color: "#255AF680",
                      cursor: "not-allowed"
                    }}
                    onClick={createTeamsChatOnclick}>
                    Join Teams
                  </span>
                ))}
            </span>
          </div>

          {item.children?.length > 0 &&
            item?.children &&
            openState[item.name] &&
            grandchildCount > 0 && (
              <div className="pl-0 pl-4">{renderAccordion(item.children, level + 1)}</div>
            )}
        </div>
      );
    });
  };

  return (
    <>
      {loading ? (
        <div
          data-testid="loading-container"
          className={
            addPeople
              ? "modal-center-spinner-full-height"
              : customMobileLandscape
              ? "landscape-chatlist-center-spinner-full-height"
              : "chatlist-center-spinner-full-height"
          }>
          <Spinner data-testid="loading-spinner" animation="border" className="custom-spinner" />
        </div>
      ) : (
        <>
          <div className={addPeople ? "modal-search-container" : "search-container"}>
            <div
              className={customMobilePortrait ? "mobile-search-wrapper" : "search-wrapper"}
              style={{ margin: addPeople ? "0px 10px" : "0px 20px" }}>
              <input
                type="text"
                className="chatsearch-input"
                placeholder="search Syngenta users"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  // handleSearch(e.target.value);
                  debouncedHandleChange.current(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e.target.value);
                  }
                }}
              />
              <span className="search-icon">
                {!showClearIcon ? (
                  <Search width={15} height={15} fill="#0D1A40" />
                ) : (
                  <X
                    data-testid="clear-search-button"
                    role="button"
                    className="cursor-pointer"
                    width={15}
                    height={15}
                    color="#0D1A40"
                    onClick={clearSearch}
                  />
                )}
              </span>
            </div>
          </div>

          {showSearchResults && (
            <div
              className={addPeople ? "modal-search-results" : "search-results"}
              data-testid="search-results">
              {searchLoader ? (
                <div className="d-flex justify-content-center align-items-center p-3">
                  <div className="spinner-border text-warning" role="status"></div>
                  <span className="search-loading-chat-text ml-2">Searching for results</span>
                </div>
              ) : searchResults?.results?.length === 0 && searchQuery.length >= 2 ? (
                <div data-testid="no-results" className="d-flex ml-3 align-items-center p-3 mb-3">
                  <EmojiFrown data-testid="no-results-icon" color="#FF3F3F" />
                  <span className="no-data-text ml-2" data-testid="no-results-text">
                    Sorry we could not find any results
                  </span>
                </div>
              ) : (
                searchResults?.results?.map(
                  (item) =>
                    item.mail != null && (
                      <div
                        key={item.id}
                        className="search-result-item"
                        data-testid="search-result-item"
                        onClick={() => {
                          handleSelectItem(item);
                        }}
                        style={{
                          padding: "10px",
                          cursor: "pointer"
                        }}
                        onMouseEnter={(e) => (e.target.style.backgroundColor = "#D9D9D9")}
                        onMouseLeave={(e) => (e.target.style.backgroundColor = "white")}>
                        {item.mail}
                      </div>
                    )
                )
              )}
            </div>
          )}

          {filteredData?.length > 0 && (
            <div
              data-testid="chat-content"
              className="p-3 bg-white rounded"
              style={{
                overflowY: "scroll",
                maxHeight: customMobilePortrait
                  ? "calc(100% - 180px)"
                  : customMobileLandscape
                  ? "calc(100% - 160px)"
                  : "calc(100% - 80px)",
                height: customMobilePortrait || customMobileLandscape ? "" : "450px",
                marginRight: "10px",
                cursor: "pointer"
              }}>
              {renderAccordion(filteredData)}
            </div>
          )}
        </>
      )}
    </>
  );
}

ChatList.propTypes = {
  trail: propTypes.string,
  setNewGroupCreated: propTypes.bool,
  // setModalJoinTeam: propTypes.bool,
  addPeople: propTypes.bool,
  setSelectedPeopleUserId: propTypes.any,
  selectedFlightDate: propTypes.any,
  combinedText: propTypes.any,
  selectedGroupNameWhileAdding: propTypes.string,
  groupsList: propTypes.array,
  groupLists: propTypes.func,
  customMobilePortrait: propTypes.bool,
  customMobileLandscape: propTypes.bool,
  setShowChatDiv: propTypes.any,
  setShowNewDiv: propTypes.any,
  setNewChat: propTypes.any,
  setDisableAddPeople: propTypes.any,
};
