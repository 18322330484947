import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement
} from "chart.js";
import propTypes from "prop-types";
import { colors, Spinner } from "../services/Common";
import Plot from "react-plotly.js";
import NewViewerBoxChart from "./NewViewerBoxChart";
import "../assests/Styles/charts.css";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement
);

function NewViewerBarChart({
  isModel,
  chartsValue,
  barData,
  noAssesment,
  noMean,
  error,
  isMobile,
  isMobileLandscape,
  isTablet,
  treatmentDataLoaded,
  navbarButtons,
  barExpand,
  highlightPlots,
  chartPointValue,
  setChartPointValue,
  zeroSubPlots,
  subPlotsLoader
}) {
  const bar = new Set();
  const means = [];
  let pointValues = false;

  if (barData?.meanValues) {
    barData?.meanValues
      .sort((a, b) => a?.num - b?.num)
      .map((obj) => {
        bar.add(obj?.num);
        means.push(parseFloat(obj?.value).toFixed(2));
      });
  }

  const colorLogic = (plot) => {
    if (plot) {
      if (plot.length <= 2) {
        if (Number(plot) <= 9) {
          return colors[Number(plot) - 1];
        } else {
          return colors[Number(plot % 9)];
        }
      } else {
        return colors[Number(plot[0]) - 1];
      }
    }
  };

  const scatterColors = (plotNo) => {
    let color = [];
    plotNo.map((item) => {
      color.push(colorLogic(item?.plot_id));
    });
    return color;
  };

  const individualSets = (data) => {
    const groupData = {};
    data.forEach((item) => {
      const { num, plot_id, value } = item;
      if (!groupData[num]) {
        groupData[num] = [];
      }
      if (value?.length > 8) {
        pointValues = true;
      }
      groupData[num].push({ num, value, plot_id });
    });
    return Object.values(groupData);
  };

  const extractDataByIndex = (dataArray, index) => {
    const extractedArray = [];

    dataArray.forEach((item) => {
      extractedArray.push(item[index]);
    });
    return extractedArray;
  };

  const result = barData?.treatmentSummary && individualSets(barData?.treatmentSummary);
  let mxLength = 0;

  result !== undefined &&
    result.forEach((item) => {
      if (Object.keys(item).length > mxLength) {
        mxLength = Object.keys(item).length;
      }
    });

  const getScatterSizes = (item, index) => {
    const sizes = [];
    for (let i = 0; i < item.length; i++) {
      sizes.push(6);
    }
    if (chartPointValue?.curveNumber === index + 1) {
      sizes[chartPointValue?.pointIndex] = 12;
    }
    return sizes;
  };
  const getScatterColors = (item, index) => {
    const colors = [];
    const color = scatterColors(item);
    for (let i = 0; i < item.length; i++) {
      colors.push(color);
    }
    if (chartPointValue?.curveNumber === index + 1) {
      colors[chartPointValue?.pointIndex] = "#FF00FF";
    }
    return colors;
  };
  const sArray = [];
  if (result) {
    for (let i = 0; i < mxLength; i++) {
      const dataArr = extractDataByIndex(result, i);
      sArray.push(dataArr);
    }
  }

  const sData = [];

  sArray.forEach((item, index) => {
    sData.push({
      x: item.map((obj) => obj?.num !== undefined && obj?.num),
      y: item.map((obj) => obj?.value !== undefined && obj?.value),
      mode: "markers",
      type: "scatter",
      marker: {
        size: getScatterSizes(item, index),
        color: getScatterColors(item, index),
        opacity: 1,
        line: { width: 0 }
      },
      customdata: item.map((obj) => obj?.plot_id !== undefined && obj?.plot_id),
      hovertemplate: "plot : %{customdata}<br>value : %{y}<extra></extra>",
      showlegend: false
    });
  });
  const getBarColors = (barLength) => {
    const colors = [];
    for (let i = 0; i < barLength; i++) {
      colors.push("rgb(75, 192, 192)");
    }
    if (chartPointValue?.curveNumber === 0) {
      colors[chartPointValue?.pointIndex] = "#FF00FF";
    }
    return colors;
  };

  const trace1 = {
    type: "bar",
    x: Array.from(bar),
    y: means,
    text: means.length < 30 && means,
    customdata: means,
    marker: { color: getBarColors(means.length) },
    hovertemplate: "%{customdata}<extra></extra>",
    textposition: "auto",
    insidetextanchor: "start",
    textangle: 0,
    showlegend: false
  };

  const plotData = [trace1, sData];

  const errorConditon = noAssesment || error || noMean || zeroSubPlots;
  const barchartMobileClass = isMobile;
  const barchartLandscapeClass = isMobileLandscape;

  const barChartLandscapeMobile = barchartLandscapeClass ? "landscapeChart" : "";
  const barChartClassName = barchartMobileClass ? "newBarChart" : barChartLandscapeMobile;
  const barChartHeight = isModel ? "700px" : isMobileLandscape ? "300px" : "320px";
  const mobileTabWidth = (isMobile || isMobileLandscape || isTablet) && !isModel;
  const desktopWidth = !isModel && !(isMobile || isMobileLandscape || isTablet);
  const desktopWidthCondition = desktopWidth ? "47vw" : "57vw";
  const mobileTabDesktopWidth = mobileTabWidth ? "100vw" : desktopWidthCondition;
  const barChartWidth = isModel
    ? "100%"
    : navbarButtons?.length === 1 || barExpand
    ? "100vw"
    : mobileTabDesktopWidth;
  const modalAndTabautosize = isModel || isTablet || !(isMobile || isMobileLandscape);
  const autosizeForOtherDevices = modalAndTabautosize ? true : false;
  const autoSizeAndResponsive = isMobile || isMobileLandscape ? false : autosizeForOtherDevices;
  const isTabletMB = isTablet ? 230 : 200;
  const isLandScapeMB = isMobileLandscape ? 350 : isTabletMB;
  const isModalMB = isModel ? 40 : isLandScapeMB;
  const isMobileMB = isMobile || isMobileLandscape ? 230 : isModalMB;
  const barChartMarginBottom = isMobileMB;
  const errorMessages =
    noAssesment || noMean
      ? "Assessment data is unavailable"
      : zeroSubPlots && subPlotsLoader === false
      ? "No data selected"
      : !zeroSubPlots || subPlotsLoader
      ? ""
      : "No Treatment Data available";
  return treatmentDataLoaded || subPlotsLoader ? (
    <div className="chart-loader-container">
      <Spinner className="chart-spinner" />
    </div>
  ) : errorConditon ? (
    <div>
      <span
        className="flight-one-date-error"
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "35px"
        }}>
        <strong>{errorMessages}</strong>
      </span>
    </div>
  ) : (
    <div
      style={{
        margin: "10px",
        overflowX: "hidden"
      }}>
      {chartsValue === "Bar" && barData?.length !== 0 && (
        <div
          className={barChartClassName}
          style={{
            height: barChartHeight,
            width: barChartWidth,
            overflowY: "hidden",
            overflowX: "hidden"
          }}>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "12px",
              fontFamily: "Helvetica",
              color: "#AAA9A9"
            }}>
            {barData?.name?.split("v:")[0]}
          </span>

          <Plot
            style={{ cursor: "pointer" }}
            data={plotData.flat(1)}
            layout={{
              scattermode: "group",
              scattergap: 0.7,
              autosize: autoSizeAndResponsive,
              margin: {
                t: 40,
                b: barChartMarginBottom,
                l: pointValues === true ? 70 : isMobile || isMobileLandscape ? 45 : 50,
                r: isMobile || isMobileLandscape ? 0 : 30
              },
              xaxis: isMobile
                ? {
                    type: "category",
                    linecolor: "#E8E8E8",
                    tickvals: Array.from(bar),
                    showgrid: false
                  }
                : {
                    type: "category",
                    linecolor: "#E8E8E8",
                    tickvals: Array.from(bar),
                    tickfont: {
                      size: isMobile && 8
                    },
                    title: {
                      text: "Treatments"
                    },
                    titlefont: {
                      family: "Helvetica",
                      size: 12,
                      color: "#AAA9A9"
                    }
                  },
              yaxis: {
                rangemode: "tozero",
                zerolinecolor: "#E8E8E8",
                linecolor: "#E8E8E8",
                showexponent: "all",
                exponentformat: "e",
                minexponent: 3,
                title: {
                  text: "Assesment Values"
                },
                titlefont: {
                  family: "Helvetica",
                  size: 12,
                  color: "#AAA9A9"
                }
              },
              annotations: isMobile && [
                {
                  x: 0.2,
                  y: -0.2,
                  text: "Treatment",
                  showarrow: false,
                  xref: "paper",
                  yref: "paper",
                  xanchor: "left",
                  yanchor: "bottom",
                  font: { size: 12, family: "Helvetica", color: "#AAA9A9" }
                }
              ],
              responsive: autoSizeAndResponsive
            }}
            onClick={(event) => {
              const type = event.points[0].data.type;
              const value = type === "bar" ? event.points[0].x : event.points[0].customdata;
              if (
                chartPointValue &&
                Object.keys(chartPointValue).length > 0 &&
                chartPointValue.curveNumber === event.points[0].curveNumber &&
                chartPointValue.pointIndex === event.points[0].pointIndex
              ) {
                setChartPointValue({});
              } else {
                setChartPointValue({
                  curveNumber: event.points[0].curveNumber,
                  pointIndex: event.points[0].pointIndex
                });
              }
              highlightPlots(type, value);
            }}
            config={{
              staticPlot: false,
              displaylogo: false,
              responsive: autoSizeAndResponsive,
              animation: false,
              modeBarButtonsToRemove: [
                "pan2d",
                "select2d",
                "autoScale2d",
                "hoverClosestCartesian",
                "zoom2d",
                "lasso2d"
              ]
            }}
          />
        </div>
      )}
      {chartsValue === "Box" && barData?.length !== 0 && (
        <div
          className={barChartClassName}
          style={{
            height: barChartHeight,
            width: barChartWidth,
            overflowY: "hidden",
            overflowX: "hidden"
          }}>
          <span
            style={{
              display: "block",
              textAlign: "center",
              fontSize: "12px",
              fontFamily: "Helvetica",
              color: "#AAA9A9"
            }}>
            {barData?.name?.split("v:")[0]}
          </span>
          <NewViewerBoxChart
            barData={barData}
            isModel={isModel}
            isMobileLandscape={isMobileLandscape}
            isTablet={isTablet}
            isMobile={isMobile}
          />
        </div>
      )}
    </div>
  );
}

NewViewerBarChart.propTypes = {
  isModel: propTypes.any,
  chartsValue: propTypes.any,
  barData: propTypes.any,
  noAssesment: propTypes.any,
  noMean: propTypes.any,
  error: propTypes.any,
  isMobileLandscape: propTypes.any,
  isMobile: propTypes.any,
  isTablet: propTypes.any,
  treatmentDataLoaded: propTypes.any,
  navbarButtons: propTypes.any,
  barExpand: propTypes.any,
  highlightPlots: propTypes.any,
  chartPointValue: propTypes.any,
  setChartPointValue: propTypes.any,
  zeroSubPlots: propTypes.any,
  subPlotsLoader: propTypes.any
};

export default React.memo(NewViewerBarChart);
