/* eslint-disable quotes */
/* eslint-disable react/jsx-key */
import { useState, useEffect } from "react";
import propTypes from "prop-types";
import {
  getAllTrialSummaryData,
  loginSyt,
  getAllTrialAssesmentData,
  getAllFilterData,
  edcGetAccessToken
} from "../services/API";
import { hoverDateFormatForTrial, colors, Spinner, customTabletDimensions } from "../services/Common";
import { Button, Row, Col } from "react-bootstrap";
import Plot from "react-plotly.js";
import "../assests/Styles/newviewer.css";
import { ChevronRight } from "react-bootstrap-icons";
import "../assests/Styles/mapviewer.css";
import { useMobileOrientation, isDesktop, isMobile } from "react-device-detect";
import { useCookies } from "react-cookie";
function NewViewerTrialSummary({
  trail,
  droneFlightDates,
  target,
  trailSumamryResult,
  handleTrialSummaryClose
}) {
  const [cookies] = useCookies(["idToken"]);
  const [trailSummaryData, setTrailSummaryData] = useState([]);
  const [trailAssesmentData, setTrailAssesmentData] = useState([]);
  const [isTrialSummary, setIsTrialSummary] = useState(true);
  const [isTarget, setIsTarget] = useState(false);
  const [allData, setAllData] = useState();
  const [loading, setLoading] = useState(true);
  const [isNotes, setIsNotes] = useState(false);
  const idToken = cookies?.idToken;
  const [formattedNotes, setFormattedNotes] = useState({});
  const { isPortrait, isLandscape } = useMobileOrientation();
  const customMobilePortrait = isMobile && isPortrait && !customTabletDimensions();
  const customMobileLandscape = isMobile && isLandscape && !customTabletDimensions();

  useEffect(() => {
    loginSyt(
      idToken,
      (res) => {
        if (res === 200) {
          edcGetAccessToken((res) => {
            if (res?.id_token !== "") {
              getAllTrialSummaryData(
                trail,
                res?.id_token,
                (data) => {
                  setTrailSummaryData(data);
                  setLoading(false);
                },
                (err) => {
                  console.log("err", err);
                  setLoading(false);
                }
              );
              getAllTrialAssesmentData(
                trail,
                res?.id_token,
                (data) => {
                  setTrailAssesmentData(data);
                  setLoading(false);
                },
                (err) => {
                  console.log("err", err);
                  setLoading(false);
                }
              );
              getAllFilterData(
                {
                  filters: [
                    {
                      comparator: "LIKE",
                      field: "id",
                      value: trail
                    }
                  ]
                },
                res?.id_token,
                (data) => {
                  setAllData(data);
                },
                (err) => {
                  console.log("err", err);
                  setLoading(false);
                }
              );
            }
          });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, [trail]);

  const handleIsTrialSummary = () => {
    setIsTrialSummary(!isTrialSummary);
    setIsTarget(false);
    setIsNotes(false);
  };

  const handleIsTaret = () => {
    setIsTarget(!isTarget);
    setIsTrialSummary(false);
    setIsNotes(false);
  };
  const handleIsNotes = () => {
    setIsNotes(!isNotes);
    setIsTrialSummary(false);
    setIsTarget(false);
  };

  const formatDateWithoutHour = (dateString) => {
    return new Date(dateString).toISOString().split("T")[0];
  };

  useEffect(() => {
    const checkData = () => {
      if (allData) {
        formatNotes();
      }
    };
    checkData();
    return () => {
      clearTimeout(checkData);
    };
  }, [allData]);

  const formatNotes = () => {
    if (!allData) {
      return;
    }
    const notes = allData.contents[0].notes || [];
    const formatted = notes.reduce((acc, note) => {
      const date = formatDateWithoutHour(note.noteDate);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(note.content);
      return acc;
    }, {});
    setFormattedNotes(formatted);
  };

  let applicationData = [];
  let plantingData = [];
  let visualData = [];
  let cropGrowthData = [];
  let x = new Set();
  let y = new Set();
  let droneXaxis = new Set();
  let droneYaxis = [];
  let droneDataOnHover = new Set();
  let ratingTypes = new Set();
  let plantDataHover = [];
  let maintenanceData = [];
  let alertsData = [];
  let maintenanceDataOnHover = new Set();
  let showChart = false;

  droneFlightDates.forEach((item) => {
    let date = item?.x.split("-");
    let dateString = date[0] + "-" + date[1] + "-" + date[2];
    droneDataOnHover.add(hoverDateFormatForTrial(dateString));
    x.add(dateString);
    y.add(item?.y);
    droneXaxis.add(dateString);
    droneYaxis.push(item?.y);
  });

  let aph = new Set();

  trailSummaryData &&
    trailSummaryData?.trialApplication &&
    trailSummaryData?.trialApplication.forEach((app) => {
      if (app?.applicationDate !== null) {
        let date = formatDateWithoutHour(app?.applicationDate)?.split("-");
        let dateString = date && date[0] + "-" + date[1] + "-" + date[2];
        let hoverText = new Date(dateString).toString().split(" ");
        let droneHoverText = hoverText[1] + " " + hoverText[2] + ", " + hoverText[3];
        aph.add(droneHoverText);
        x.add(formatDateWithoutHour(app?.applicationDate));
        y.add("Application");
        applicationData.push({
          id: app?.id,
          x: formatDateWithoutHour(app?.applicationDate),
          y: "Application"
        });
      }
    });

  trailSummaryData &&
    trailAssesmentData?.trialAssessmentDef &&
    trailAssesmentData?.trialAssessmentDef.forEach((item) => {
      if (item?.crop?.plantingDate !== null) {
        let date = formatDateWithoutHour(item?.crop?.plantingDate).split("-");
        let dateString = date[0] + "-" + date[1] + "-" + date[2];
        plantDataHover.push(hoverDateFormatForTrial(dateString));
        x.add(formatDateWithoutHour(item?.crop?.emergenceDate));
        x.add(formatDateWithoutHour(item?.crop?.plantingDate));
        y.add("Planting Date");
        plantingData.push({
          x: formatDateWithoutHour(item?.crop?.plantingDate),
          y: "Planting Date"
        });
      }
    });

  let max = new Set();
  max.add(0);
  trailSummaryData &&
    trailAssesmentData?.trialAssessmentDef &&
    trailAssesmentData?.trialAssessmentDef.forEach((item) => {
      if (item?.cropStage?.scale === "BBCH" && item?.cropStage?.majority !== null) {
        max.add(item?.cropStage?.majority);
        cropGrowthData.push({
          x: formatDateWithoutHour(item?.ratingDate),
          y: item?.cropStage?.majority
        });
      }
    });

  trailSummaryData &&
    trailSummaryData?.trialSummary?.generalTrial &&
    x.add(formatDateWithoutHour(trailSummaryData?.trialSummary?.generalTrial?.initiationDate));

  let vsh = new Set();
  trailAssesmentData &&
    trailAssesmentData?.trialAssessmentDef &&
    trailAssesmentData?.trialAssessmentDef.forEach((item) => {
      if (item?.ratingDate !== null) {
        let date = formatDateWithoutHour(item?.ratingDate).split("-");
        let dateString = date[0] + "-" + date[1] + "-" + date[2];
        let hoverText = new Date(dateString).toString().split(" ");
        let droneHoverText = hoverText[1] + " " + hoverText[2] + ", " + hoverText[3];
        vsh.add(droneHoverText);
        x.add(formatDateWithoutHour(item?.ratingDate));
        y.add(item?.ratingType);
        ratingTypes.add(item?.ratingType);
        visualData.push({
          x: formatDateWithoutHour(item?.ratingDate),
          y: item?.ratingType ? item?.ratingType : "Manual"
        });
      }
    });

  const individualSets = (data) => {
    const groupData = {};
    data.forEach((item) => {
      const { x, y } = item;

      if (!groupData[y]) {
        groupData[y] = [];
      }
      groupData[y].push({ x, y });
    });
    return Object.values(groupData);
  };

  const visualdataGroup = visualData && individualSets(visualData);

  const uniqueVisualData = [];
  visualdataGroup.forEach((item) => {
    const hasSeen = new Set();
    const uniqueData = [];

    item.forEach((obj) => {
      const data = JSON.stringify(obj);
      if (!hasSeen.has(data)) {
        hasSeen.add(data);
        if (obj?.y !== null) {
          uniqueData.push(obj);
        }
      }
    });
    uniqueVisualData.push(uniqueData);
  });

  trailSummaryData &&
    trailSummaryData?.maintenances &&
    trailSummaryData?.maintenances.forEach((item) => {
      if (item?.date !== null) {
        let dateString = formatDateWithoutHour(item?.date).split(" ")[0];
        let hoverText = new Date(dateString).toString().split(" ");
        let maintenanceText =
          hoverText[2] + " " + hoverText[1].toUpperCase() + " , " + hoverText[3];
        maintenanceDataOnHover.add(maintenanceText);
        maintenanceData.push({
          x: formatDateWithoutHour(item?.date).split(" ")[0],
          y: "Maintenance",
          id: item?.type
        });
      }
    });

  allData?.contents.forEach((item) => {
    if (item?.notes && item?.notes.length > 0) {
      item?.notes.forEach((obj) => {
        let dateString = obj?.date !== null && formatDateWithoutHour(obj?.noteDate);
        let hoverText = new Date(dateString).toString().split(" ");
        let AlertDate = hoverText[1] + " " + hoverText[2] + ", " + hoverText[3];
        let noteText = obj?.content.includes(":") ? obj?.content.split(":") : obj?.content;
        let AlertText = obj?.content.includes(":")
          ? noteText.slice(1, noteText.length).join(" ")
          : obj?.content;
        alertsData.push({ date: dateString, content: AlertText, convertedDate: AlertDate });
      });
    }
  });

  alertsData.sort((a, b) => new Date(a?.date) - new Date(b?.date)).reverse();

  const alertsGrouping = {};
  alertsData.forEach((item) => {
    const { date, content } = item;
    if (!alertsGrouping[date]) {
      alertsGrouping[date] = [];
    }
    alertsGrouping[date].push({ content });
  });

  const dataWithTwoorMoreIds = (data) => {
    const md = {};
    data.forEach((item) => {
      const key = `${item?.x}-${item?.y}`;
      if (md[key]) {
        md[key].ids.push(item?.id.slice(0, 4));
      } else {
        md[key] = {
          x: item?.x,
          y: item?.y,
          ids: [item?.id.slice(0, 4)]
        };
      }
    });
    return Object.values(md);
  };

  const trace = [];
  const maintainenceTrace = {
    x: dataWithTwoorMoreIds(maintenanceData).map(
      (item) => (item?.x !== undefined || item?.x !== null) && item?.x
    ),
    y: dataWithTwoorMoreIds(maintenanceData).map(
      (item) => (item?.y !== undefined || item?.y !== null) && item?.y
    ),
    text: dataWithTwoorMoreIds(maintenanceData).map((item) =>
      (item?.ids !== undefined || item?.ids !== null || item?.ids.length !== 0) &&
        item?.ids.length <= 1
        ? item?.ids.join("")
        : Array.from(new Set(item?.ids)).join("+")
    ),
    mode: "markers+text",
    type: "scatter",
    marker: { size: 12, color: "#006D82" },
    customdata: Array.from(maintenanceDataOnHover),
    hovertemplate: "%{x}<extra></extra>",
    textposition: "top center",
    yaxis: "y2",
    showlegend: false
  };
  if (maintenanceData?.length > 0) trace.push(maintainenceTrace);
  const applicationTrace = {
    x: dataWithTwoorMoreIds(applicationData).map(
      (item) => (item?.x !== undefined || item?.x !== null) && item?.x
    ),
    y: dataWithTwoorMoreIds(applicationData).map(
      (item) => (item?.y !== undefined || item?.y !== null) && item?.y
    ),
    text: dataWithTwoorMoreIds(applicationData).map((item) =>
      (item?.ids !== undefined || item?.ids !== null || item?.ids.length !== 0) &&
        item?.ids.length <= 1
        ? item?.ids.join("")
        : item?.ids.join("+")
    ),
    mode: "markers+text",
    type: "scatter",
    marker: { size: 12, color: "#859940" },
    customdata: Array.from(aph),
    hovertemplate: "%{x}<extra></extra>",
    textposition: "top center",
    yaxis: "y2",
    showlegend: false
  };
  if (applicationData?.length > 0) trace.push(applicationTrace);

  const plantingTrace = {
    x: plantingData.map((item) => (item?.x !== undefined || item?.x !== null) && item?.x),
    y: plantingData.map((item) => (item?.y !== undefined || item?.y !== null) && item?.y),
    mode: "markers",
    type: "scatter",
    marker: { size: 12, color: "#FF7373" },
    customdata: Array.from(plantDataHover),
    hovertemplate: "%{x}<extra></extra>",
    yaxis: "y2",
    showlegend: false
  };
  if (plantingData?.length > 0) trace.push(plantingTrace);

  const cropGrowthTrace = {
    x: cropGrowthData.map((item) => (item?.x !== undefined || item?.x !== null) && item?.x),
    y: cropGrowthData.map((item) => (item?.y !== undefined || item?.y !== null) && item?.y),
    mode: "markers",
    type: "scatter",
    hovertemplate: "%{y}<br>%{x}<extra></extra>",
    marker: { size: 12, color: "#3EB62A" },
    showlegend: false
  };
  if (cropGrowthData?.length > 0) trace.push(cropGrowthTrace);

  const tickValues = Array.from(ratingTypes);
  const tickText = tickValues.map((val, index) => {
    let tickColor = colors[index];
    if (val === "CONTRO") {
      return `<span style="color:#5A7FFF">Contro</span>`;
    } else if (val === "PHYGEN") {
      return `<span style="color:#FFA841">Phygen</span>`;
    } else return `<span style="color:${tickColor}">${val}</span>`;
  });

  var visualDataXCollection = [];
  var visualDataYCollection = [];
  uniqueVisualData.forEach((item) => {
    (visualDataXCollection = [
      ...visualDataXCollection,
      ...item.map((obj) => obj?.x !== undefined && obj?.x)
    ]),
      (visualDataYCollection = [
        ...visualDataYCollection,
        ...item.map((obj) => obj?.y !== undefined && obj?.y)
      ]);
  });

  const visualDataTrace = {
    x: visualDataXCollection,
    y: visualDataYCollection,
    mode: "markers",
    type: "scatter",
    customdata: Array.from(vsh),
    hovertemplate: "%{x}<extra></extra>",
    marker: {
      size: 12,
      color: "#F1C40F"
    },
    yaxis: "y2",
    showlegend: true,
    name: "Manual<br>Assessment &nbsp; &nbsp;"
  };

  const droneTrace = {
    x: Array.from(droneXaxis),
    y: Array.from(droneYaxis),
    mode: "markers",
    type: "scatter",
    customdata: Array.from(droneDataOnHover),
    hovertemplate: "%{x}<extra></extra>",
    marker: { size: 12, color: "#A64BEE" },
    showlegend: false,
    yaxis: "y2"
  };

  if (droneXaxis.size > 0 && droneYaxis.length > 0) trace.push(droneTrace);

  var chartData = [
    cropGrowthTrace,
    maintainenceTrace,
    applicationTrace,
    plantingTrace,
    visualDataTrace,
    droneTrace
  ];

  showChart =
    (cropGrowthTrace?.y?.length > 0) |
      (maintainenceTrace?.y?.length > 0) |
      (applicationTrace?.y?.length > 0) |
      (plantingTrace?.y?.length > 0) |
      (visualDataTrace?.y?.length > 0) |
      (droneTrace?.y?.length > 0)
      ? true
      : false;

  const layout = {
    margin: {
      t: customMobileLandscape ? 0 : 15,
      r: 0,
      l: window.innerWidth > 440 ? 130 : 90,
      b: 50
    },
    width: customMobilePortrait && "100%",
    showlegend: true,
    legend: { x: 1, y: 0.5 },
    autosize: customMobilePortrait || customMobilePortrait ? false : true,
    xaxis: {
      type: "date",
      linecolor: "#E8E8E8",
      tickformat: "%Y-%m-%d"
    },
    yaxis: {
      // Growth stage data
      scaleanchor: "x",
      domain:
        (cropGrowthTrace?.y?.length > 0) &
          (maintainenceTrace?.y?.length == 0) &
          (applicationTrace?.y?.length == 0) &
          (plantingTrace?.y?.length == 0) &
          (visualDataTrace?.y?.length == 0) &
          (droneTrace?.y?.length == 0)
          ? [0, 1]
          : cropGrowthTrace?.y?.length == 0
            ? [0, 1]
            : [0, 0.2],
      linecolor: "#E8E8E8",
      title: {
        text: cropGrowthTrace.y?.length > 0 && "Growth<br> Stage",
        font: {
          size: 13
        },
        standoff: 25,
        side: "left"
      },
      visible: cropGrowthTrace.y?.length > 0 ? true : false
    },
    yaxis2: {
      // Maintenance data
      visible:
        (maintainenceTrace?.y?.length > 0) |
          (applicationTrace?.y?.length > 0) |
          (plantingTrace?.y?.length > 0) |
          (visualDataTrace?.y?.length > 0) |
          (droneTrace?.y?.length > 0)
          ? true
          : false,
      scaleanchor: "x",
      domain:
        (cropGrowthTrace?.y?.length > 0) &
          (maintainenceTrace?.y?.length == 0) &
          (applicationTrace?.y?.length == 0) &
          (plantingTrace?.y?.length == 0) &
          (visualDataTrace?.y?.length == 0) &
          (droneTrace?.y?.length == 0)
          ? [0.99, 1]
          : cropGrowthTrace?.y?.length == 0
            ? [0, 1]
            : [0.2, 1],
      linecolor: "#E8E8E8",
      titlefont: { color: "#1f77b4" }
    },
    yaxis3: {
      // Application data
      scaleanchor: "x",
      zerolinecolor: "#E8E8E8",
      tickfont: {
        color: "#FF7373"
      },
      linecolor: "#E8E8E8"
    },
    yaxis4: {
      //  Planting data
      scaleanchor: "x",
      linecolor: "#E8E8E8"
    },
    yaxis5: {
      // Visual assessment
      tickvals: tickValues,
      ticktext: tickText,
      linecolor: "#E8E8E8"
    },
    yaxis6: {
      // Drone data
      tickfont: {
        color: "#A64BEE"
      },
      linecolor: "#E8E8E8"
    },
    height: customMobileLandscape ? 600 : " ",
    responsive: customMobilePortrait || customMobileLandscape ? false : true
  };

  return (
    <>
      {loading ? (
        <div className="trial-summary-popup center-spinner">
          <Spinner animation="border" data-testid="loading-spinner" className="custom-spinner" />
        </div>
      ) : (
        <>
          <Row
            className="col-12 pl-0 pr-0 ml-0"
            style={{
              position: "unset",
              height: "100%",
              borderBottom: "1px solid #DDDDDD",
              width: "100%"
            }}>
            <>
              <div
                className={
                  trailSumamryResult &&
                    (isDesktop || customTabletDimensions() || customMobilePortrait)
                    ? "col-2 d-flex flex-row justify-content-center"
                    : "col-3 d-flex flex-row justify-content-center"
                }
                style={{
                  backgroundColor: isTrialSummary ? "#FFE8BC" : "white",
                  minHeight: "60px",
                  alignItems: "center"
                }}>
                <Button
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    height: "auto",
                    backgroundColor: isTrialSummary === true ? "#FFE8BC" : "white",
                    boxShadow: "none",
                    borderColor: "transparent",
                    border: "none",
                    padding: "0px"
                  }}
                  onClick={handleIsTrialSummary}>
                  <span
                    style={{
                      color: "#0D1A40",
                      fontSize: "12px",
                      marginTop: trailSumamryResult ? "0px" : "12px",
                      fontFamily: "Helvetica"
                    }}>
                    Trial Summary
                  </span>
                </Button>
              </div>
              <div
                className={
                  trailSumamryResult &&
                    (isDesktop || customTabletDimensions() || customMobilePortrait)
                    ? "col-2 d-flex flex-row justify-content-center"
                    : "col-3 d-flex flex-row justify-content-center"
                }
                style={{
                  backgroundColor: isTarget ? "#FFE8BC" : "white",
                  minHeight: "60px",
                  alignItems: "center"
                }}>
                <Button
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    borderColor: "transparent",
                    border: "none",
                    height: "auto",
                    backgroundColor: isTarget === true ? "#FFE8BC" : "white",
                    boxShadow: "none",
                    padding: "0px"
                  }}
                  onClick={handleIsTaret}>
                  <span
                    style={{
                      color: "#0D1A40",
                      fontSize: "12px",
                      marginTop: trailSumamryResult ? "0px" : "12px",
                      fontFamily: "Helvetica"
                    }}>
                    Targets
                  </span>
                </Button>
              </div>
              <div
                className={
                  trailSumamryResult &&
                    (isDesktop || customTabletDimensions() || customMobilePortrait)
                    ? "col-2 d-flex flex-row justify-content-center"
                    : "col-3 d-flex flex-row justify-content-center"
                }
                style={{
                  backgroundColor: isNotes ? "#FFE8BC" : "white",
                  minHeight: "60px",
                  alignItems: "center"
                }}>
                <Button
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    borderColor: "transparent",
                    border: "none",
                    height: "auto",
                    backgroundColor: isNotes === true ? "#FFE8BC" : "white",
                    boxShadow: "none",
                    padding: "0px"
                  }}
                  onClick={handleIsNotes}>
                  <span
                    style={{
                      color: "#0D1A40",
                      fontSize: "12px",
                      marginTop: trailSumamryResult ? "0px" : "12px",
                      fontFamily: "Helvetica"
                    }}>
                    ARM Notes
                  </span>
                </Button>
              </div>
              {trailSumamryResult && (
                <>
                  <div
                    style={{ left: isDesktop ? "13%" : customTabletDimensions() ? "13%" : "" }}
                    className={
                      trailSumamryResult && customMobilePortrait
                        ? "col-5 d-flex flex-row justify-content-center align-items-center pl-0 pr-0"
                        : trailSumamryResult && (customMobilePortrait || customMobileLandscape)
                          ? "col-3 d-flex flex-row justify-content-center align-items-center pl-0 pr-0"
                          : trailSumamryResult && customTabletDimensions()
                            ? "col-5 d-flex flex-row justify-content-center align-items-center pl-0"
                            : "col-5 d-flex flex-row justify-content-center align-items-center"
                    }>
                    <a href={`/app/viewer?trial=${trail}`}>
                      {trailSumamryResult && (
                        <div>
                          <Button
                            className="d-flex align-items-center map-data-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(`/app/viewer?trial=${trail}`, "_self");
                            }}>
                            <span>DATA</span>
                            <ChevronRight className="pl-1" height={14} width={14} />
                          </Button>
                        </div>
                      )}
                    </a>
                    <div>
                      {trailSumamryResult && (
                        <button
                          style={{
                            position: "relative",
                            fontSize: "22px",
                            border: "none",
                            backgroundColor: "transparent",

                            left: customTabletDimensions()
                              ? "45px"
                              : isDesktop
                                ? "75px"
                                : customMobileLandscape
                                  ? "33px"
                                  : " 45px"
                          }}
                          onClick={handleTrialSummaryClose}
                          aria-label="Close">
                          &times;
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          </Row>

          {isTrialSummary === true ? (
            <div
              className={
                customMobilePortrait
                  ? "mobile-trial-summary"
                  : customMobileLandscape
                    ? "mobile-ls-cont"
                    : "null"
              }
              hidden={!showChart}>
              <Plot
                style={{ width: "100%" }}
                data={chartData}
                layout={layout}
                config={{
                  staticPlot: false,
                  displaylogo: false,
                  // responsive: customMobilePortrait || customMobileLandscape ? false : true,
                  animation: false,
                  modeBarButtonsToRemove: [
                    "pan2d",
                    "select2d",
                    "autoScale2d",
                    "hoverClosestCartesian",
                    "zoom2d",
                    "lasso2d"
                  ]
                }}></Plot>
            </div>
          ) : isTarget === true && target ? (
            <div
              className="d-flex"
              style={{
                width: "100vw",
                height: customMobileLandscape && "100vh",
                paddingLeft: "8px"
              }}>
              <ul className="list-unstyled mb-2" style={{ paddingLeft: 0 }}>
                {target.map((item, key) => (
                  <li
                    key={key}
                    style={{
                      fontSize: "12px",
                      fontFamily: "Helvetica",
                      color: "#0D1A40",
                      fontWeight: 400,
                      lineHeight: 2.5
                    }}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ) : isNotes ? (
            <div style={{ height: "100vh", overflowY: "scroll" }}>
              {Object.keys(formattedNotes).length === 0 ? (
                <Row className="my-3">
                  <Col>
                    <p className="text-center">No notes available for this trial.</p>
                  </Col>
                </Row>
              ) : (
                Object.keys(formattedNotes).map((date) => (
                  <Row
                    key={date}
                    className="my-3 border-bottom pb-3"
                    style={{
                      width: "100%",
                      position: "relative",
                      left:
                        customMobilePortrait || customMobileLandscape || customTabletDimensions()
                          ? "10px"
                          : "15px"
                    }}>
                    <Col xs={3} md={2} className="text-md-end mb-2 mb-md-0">
                      <span className="date-TrialSummary">{date}</span>
                    </Col>
                    <Col xs={9} md={9}>
                      {formattedNotes[date].map((content, contentIndex) => (
                        <p key={contentIndex} className="mb-2 content-TrialSummary">
                          {content}
                        </p>
                      ))}
                    </Col>
                  </Row>
                ))
              )}
            </div>
          ) : null}
        </>
      )}
    </>
  );
}

NewViewerTrialSummary.propTypes = {
  trail: propTypes.any,
  droneFlightDates: propTypes.any,
  target: propTypes.any,
  trailSumamryResult: propTypes.any,
  setTrailSummaryResult: propTypes.any,
  handleTrialSummaryClose: propTypes.any
};

export default NewViewerTrialSummary;
