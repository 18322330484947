/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import { CheckCircleFill } from "react-bootstrap-icons";
import { saveReport, sendReport } from "../services/API";
import { DateFormatingForReportingModal } from "../services/Common";
import { useCookies } from "react-cookie";
export default function GeneralReportModal({
  openGeneralReportModal,
  setOpenGeneralReportModal,
  successModal,
  setSuccessModal,
  isTablet
}) {
  const [cookies] = useCookies(["userEmail", "cognitoFirstName", "cognitoLastName"]);
  const userEmail = cookies?.userEmail;
  const firstName = cookies?.cognitoFirstName;
  const lastName = cookies?.cognitoLastName;
  const [description, setDescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let receiverEmail = "dlglobalinseasoninsights@syngenta.com";

    let sendFeedbackPayload = {
      receiver_email: receiverEmail,
      subject: "In-season Insights Feedback",
      body: "In-season Insights User's Feedback Details",
      page_url: window.location.href,
      name: firstName + " " + lastName,
      email: userEmail,
      current_trial: "",
      flight_date: "",
      feedback_type: "",
      details: "",
      descriptions: description,
      row_no: "",
      submitted_datetime: DateFormatingForReportingModal(new Date())
    };
    sendReport(
      sendFeedbackPayload,
      (res) => {
        if (res?.status_code == 200) {
          setOpenGeneralReportModal(false);
          setSuccessModal(true);
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const handleClose = () => {
    setOpenGeneralReportModal(false);
  };

  return (
    <>
      <Modal
        className={isTablet ? "tablet-trial-report-modal" : "trial-report-modal"}
        show={openGeneralReportModal}
        onHide={handleClose}
        backdrop="true"
        centered>
        <Modal.Header>
          <Modal.Title className="mb-2">
            <span className="font-size-large report-title">Report an issue</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="report-modal-body">
          <div className="mb-3 deep-cove-color font-size-small font-weight-400">
            Have any issues or thoughts on how we improve In-Season Insights, send us a note here
            and we will take actions or follow-up with you to get more details.
          </div>
          <div className="mt-3 description-textarea">
            <label className="mb-2 font-size-small">
              <strong> Description</strong>
            </label>
            <textarea
              data-testid="description-textarea"
              rows={isTablet ? "6" : "4"}
              cols="45"
              placeholder="Type your description here"
              className="form-control description-box mb-3"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button onClick={handleClose} className="report-modal-cancel">
            <span className="deep-cove-color">CANCEL</span>
          </button>
          <button
            onClick={handleSubmit}
            className="report-modal-submit"
            style={{
              opacity: description === "" ? 0.3 : 1
            }}
            disabled={description === ""}>
            <span>SUBMIT</span>
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        className={isTablet ? "tablet-success-modal" : "success-modal"}
        show={successModal}
        // onHide={() => setSuccessModal(false)}
        centered>
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex flex-column justify-content-center align-items-center py-5">
            <CheckCircleFill color="#2EB800" height={48} width={48} />
            <div className="pt-3">
              <span className="font-24 deep-cove-color">Issue has been reported successfully</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            onClick={() => {
              setSuccessModal(false);
            }}
            className="success-modal-ok">
            <span>OK</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

GeneralReportModal.propTypes = {
  openGeneralReportModal: propTypes.any,
  setOpenGeneralReportModal: propTypes.any,
  successModal: propTypes.any,
  setSuccessModal: propTypes.any,
  isTablet: propTypes.any
};
