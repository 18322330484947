import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement
} from "chart.js";
import { Box, Typography } from "@mui/material";
import propTypes from "prop-types";
import { loginSyt, barChartApi } from "../services/API";
import LineChart from "./LineChart";
import BoxPlot from "./BoxPlot";
import CompareChart from "./CompareChart";
import { useMsal } from "@azure/msal-react";
import { colors } from "../services/Common";
import { useCookies } from "react-cookie";
import "../assests/Styles/viewer.css";
import Plot from "react-plotly.js";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement
);

function BarChart({
  assessment,
  assessment1,
  trailname,
  measurements,
  collectionId,
  tabIndex,
  chartsValue,
  rightDrawer,
  selectedValue
}) {
  const [barData, setBarData] = useState([]);
  const [error, setError] = useState(false);
  const [noAssesment, setNoAssesment] = useState(false);
  const [noMean, setNoMean] = useState(false);
  const [cookies,removeCookie] = useCookies(["idToken"]);

  const idToken = cookies?.idToken;

  useEffect(() => {
    setNoAssesment(false);
    setNoMean(false);
    const trail = measurements.filter((item) => item?.name === trailname);
    if (trail.length === 0) {
      setNoAssesment(true);
    }
    setError(false);
    loginSyt(idToken, (res) => {
      if (res === 200) {
        barChartApi(
          {
            assessment: assessment?.vector,
            trial_id: trail[0]?.trial_id,
            collection_id: collectionId,
            subplot_id: selectedValue === "All subplots" || !selectedValue ? "" : selectedValue
          },
          (res) => {
            if (
              res.length <= 0 ||
              res?.meanValues?.length === 0 ||
              res?.treatmentSummary?.length === 0
            ) {
              setNoAssesment(true);
              setNoMean(true);
            } else {
              setNoAssesment(false);
              setBarData(res);
            }
          },
          (err) => {
            if (err === "Request failed with status code 403") {
              setError(true);
            }
          }
        );
      } else {
        const { instance } = useMsal();
        instance.logoutRedirect({
          postLogoutRedirectUri: "/app",
          mainWindowRedirectUri: "/app" // redirects the top level app after logout
        });
        removeCookie("msal.interaction.status");
      }
    });
    // }
  }, [assessment?.vector, trailname, collectionId, selectedValue, idToken]);

  const bar = new Set();
  const means = [];

  if (barData?.meanValues) {
    barData?.meanValues
      .sort((a, b) => a?.num - b?.num)
      .map((obj) => {
        bar.add(obj?.num);
        means.push(parseFloat(obj?.value).toFixed(2));
      });
  }

  const min = Math.min(...means);
  const max = Math.max(...means);

  const colorLogic = (plot) => {
    if (plot) {
      if (plot.length <= 2) {
        if (Number(plot) <= 9) {
          return colors[Number(plot) - 1];
        } else {
          return colors[Number(plot % 9)];
        }
      } else {
        return colors[Number(plot[0]) - 1];
      }
    }
  };

  const scatterColors = (plotNo) => {
    let color = [];
    plotNo.map((item) => {
      color.push(colorLogic(item?.plot_id));
    });
    return color;
  };

  const individualSets = (data) => {
    const groupData = {};
    data.forEach((item) => {
      const { num, plot_id, value } = item;
      if (!groupData[num]) {
        groupData[num] = [];
      }
      groupData[num].push({ num, value, plot_id });
    });
    return Object.values(groupData);
  };

  const extractDataByIndex = (dataArray, index) => {
    const extractedArray = [];

    dataArray.forEach((item) => {
      extractedArray.push(item[index]);
    });
    return extractedArray;
  };

  const result = barData?.treatmentSummary && individualSets(barData?.treatmentSummary);
  let mxLength = 0;

  result !== undefined &&
    result.forEach((item) => {
      if (Object.keys(item).length > mxLength) {
        mxLength = Object.keys(item).length;
      }
    });

  const sArray = [];
  if (result) {
    for (let i = 0; i < mxLength; i++) {
      const dataArr = extractDataByIndex(result, i);
      sArray.push(dataArr);
    }
  }

  const sData = [];

  sArray.forEach((item) => {
    sData.push({
      x: item.map((obj) => obj?.num !== undefined && obj?.num),
      y: item.map((obj) => obj?.value !== undefined && obj?.value),
      mode: "markers",
      type: "scatter",
      marker: { color: scatterColors(item) },
      customdata: item.map((obj) => obj?.plot_id !== undefined && obj?.plot_id),
      hovertemplate: "plot : %{customdata}<br>value : %{y}<extra></extra>",
      showlegend: false
    });
  });

  const trace1 = {
    type: "bar",
    x: Array.from(bar),
    y: means,
    text: means.length < 30 && means,
    customdata: means,
    marker: { color: "rgb(75, 192, 192)" },
    hovertemplate: "%{customdata}<extra></extra>",
    textposition: "auto",
    insidetextanchor: "start",
    textangle: 0,
    showlegend: false
  };

  const plotData = [trace1, sData];
  const noRightDrawerWidth = !rightDrawer ? "72vw" : "57vw";

  return (noAssesment || error || (min === 0 && max === 0) || noMean) && tabIndex !== "three" ? (
    <div style={{ height: window.innerHeight < 440 ? "130px" : "280px" }}>
      <Typography
        variant="title"
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "35px"
        }}>
        <strong>
          {noAssesment || (min === 0 && max === 0) || noMean
            ? "Assessment data is unavailable"
            : "No Treatment Data available"}
        </strong>
      </Typography>
    </div>
  ) : (
    <Box
      sx={{
        margin: "10px",
        overflowX: "hidden"
      }}>
      {tabIndex === "one" && (
        <>
          {chartsValue === "Bar" && (
            <Box
              sx={{
                height: window.innerHeight < 440 ? "150px" : "320px",
                width: window.innerWidth < 440 ? "90vw" : noRightDrawerWidth,
                overflowY: "hidden",
                overflowX: "hidden"
              }}>
              <>
                <Typography
                  variant="subtitle"
                  style={{ display: "flex", justifyContent: "center" }}>
                  {barData?.name?.split("v:")[0]}
                </Typography>

                <Plot
                  style={{ cursor: "pointer" }}
                  data={plotData.flat(1)}
                  layout={{
                    scattermode: "group",
                    scattergap: 0.7,
                    autosize: true,
                    margin: {
                      t: 40,
                      b: window.innerHeight < 440 ? 350 : 200,
                      l: 50,
                      r: 0
                    },
                    xaxis: {
                      type: "category",
                      linecolor: "#E8E8E8",
                      tickvals: Array.from(bar),
                      tickfont: {
                        size: window.innerWidth < 440 && 8
                      },
                      title: {
                        text: window.innerHeight > 440 ? "Treatments" : ""
                      },
                      titlefont: {
                        family: "Helvetica",
                        size: 12
                      }
                    },
                    yaxis: {
                      rangemode: "tozero",
                      zerolinecolor: "#E8E8E8",
                      linecolor: "#E8E8E8",
                      title: {
                        text: window.innerHeight > 440 ? "Assesment Values" : ""
                      },
                      titlefont: {
                        family: "Helvetica",
                        size: 12
                      }
                    },
                    responsive: true
                  }}
                  config={{
                    staticPlot: false,
                    displaylogo: false,
                    responsive: true,
                    animation: false,
                    modeBarButtonsToRemove: [
                      "pan2d",
                      "select2d",
                      "autoScale2d",
                      "hoverClosestCartesian",
                      "zoom2d",
                      "lasso2d"
                    ]
                  }}
                />
              </>
            </Box>
          )}
          {chartsValue === "Box" && (
            <>
              <Box
                sx={{
                  height: window.innerHeight < 440 ? "150px" : "320px",
                  width: window.innerWidth < 440 ? "90vw" : noRightDrawerWidth,
                  overflowY: "hidden",
                  overflowX: "hidden"
                }}>
                <Typography variant="subtitle" style={{ display: "block", textAlign: "center" }}>
                  {barData?.name?.split("v:")[0]}
                </Typography>
                <BoxPlot barData={barData} rightDrawer={rightDrawer} />
              </Box>
            </>
          )}
        </>
      )}
      {tabIndex === "two" && (
        <Box
          sx={{
            margin: "10px 10px 0px 10px",
            overflowX: "hidden",
            overflowY: "hidden",
            height: window.innerHeight < 440 ? "160px" : "310px"
          }}>
          <>
            <LineChart
              measurements={measurements}
              rightDrawer={rightDrawer}
              assessment={assessment}
              trailname={trailname}
              collectionId={collectionId}
              selectedValue={selectedValue}
            />
          </>
        </Box>
      )}
      {tabIndex === "three" && assessment !== undefined && assessment1 !== undefined && (
        <Box
          sx={{
            overflowX: "hidden",
            overflowY: "hidden",
            height: window.innerHeight < 440 ? "160px" : "310px"
          }}>
          <CompareChart
            assessment1={assessment}
            assessment2={assessment1}
            trialname={trailname}
            measurements={measurements}
            collectionId={collectionId}
            selectedValue={selectedValue}
            rightDrawer={rightDrawer}
          />
        </Box>
      )}
      {tabIndex === "three" && (assessment === undefined || assessment1 === undefined) && (
        <Typography
          variant="title"
          style={{ display: "block", textAlign: "center", marginBottom: "10px", height: 180 }}>
          <strong>Please select two assessments!</strong>
        </Typography>
      )}
    </Box>
  );
}

BarChart.propTypes = {
  assessment: propTypes.any,
  assessment1: propTypes.any,
  trailname: propTypes.any,
  measurements: propTypes.any,
  collectionId: propTypes.any,
  tabIndex: propTypes.any,
  chartsValue: propTypes.any,
  rightDrawer: propTypes.any,
  selectedValue: propTypes.any
};

export default React.memo(BarChart);
