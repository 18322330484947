import { Amplify } from "aws-amplify";
import { signOut } from "aws-amplify/auth";
import awsConfig from "../services/amplifyconfiguration.json";
import { useMsal } from "@azure/msal-react";

Amplify.configure(awsConfig);

export default function SignOutButton() {

  const handleSignOut = async () => {
    console.log("inside handleSignOut");
    const { instance } = useMsal();

  instance.logoutRedirect({
    postLogoutRedirectUri: "/app",
    mainWindowRedirectUri: "/app" // redirects the top level app after logout
  });
  sessionStorage.removeItem("msal.interaction.status");
    try {
      await signOut();
      sessionStorage.clear();
      localStorage.clear();
      console.log("Successfully signed out");
    } catch (err) {
      console.log("Error signing out: ", err);
    }
  };
 
return handleSignOut();
}
