import { useState, useEffect } from "react";
import { Modal, Button, InputGroup, FormControl } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import { Search, X, InfoCircleFill, CheckCircleFill } from "react-bootstrap-icons";
import { formatFlightDate, DateFormatingForReportingModal } from "../services/Common";
import { sendReport, saveReport } from "../services/API";
import { useCookies } from "react-cookie";
export default function ReportModal({
  reportingPlotId,
  reportingFlightDate,
  selectedOption,
  assessmentDateType,
  showReportingModal,
  setShowReportingModal,
  setSuccessModal,
  successModal,
  setOpenTrialReportModal,
  deepLinkUrl
}) {
  const [cookies] = useCookies(["userEmail", "cognitoFirstName", "cognitoLastName"]);
  const userEmail = cookies?.userEmail;
  const firstName = cookies?.cognitoFirstName;
  const lastName = cookies?.cognitoLastName;
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [buttonClicked, setButtonClicked] = useState("");
  const [textAreaDisabled, setTextAreaDisabled] = useState(true);
  const [description, setDescription] = useState("");
  const flightAssesments = assessmentDateType.find(
    (item) => formatFlightDate(item?.assessmentdate) === reportingFlightDate
  );

  const [filteredAssessments, setFilteredAssessments] = useState(flightAssesments?.categories);
  const [assesmentSearchError, setAssesmentSearchError] = useState(false);

  useEffect(() => {
    if (buttonClicked === "Assessments") {
      if (checkBoxes?.length > 0) {
        setTextAreaDisabled(false);
      } else {
        setTextAreaDisabled(true);
      }
    }
  }, [checkBoxes]);

  const onChangeForFiltering = (e) => {
    setSearchText(e.target.value);
    if (flightAssesments) {
      const filteredAssessmentData = Object.keys(flightAssesments?.categories).reduce(
        (category, type) => {
          const filterArray = flightAssesments?.categories[type].filter((test) =>
            test?.toLowerCase().includes(e.target.value?.toLowerCase())
          );
          if (filterArray?.length > 0) {
            category[type] = filterArray;
          }
          return category;
        },
        {}
      );
      setFilteredAssessments(filteredAssessmentData);
      setAssesmentSearchError(Object.keys(filteredAssessmentData)?.length === 0);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let buttonType = buttonClicked === "Assessments" ? "assessment" : buttonClicked.toLowerCase();
    let receiverEmail = "DDCOperationsTeam@syngenta.com";
    let details = buttonClicked === "Assessments" ? checkBoxes : "";
    let payload = {
      trial_name: selectedOption,
      plot_number: reportingPlotId,
      flight_date: formatFlightDate(reportingFlightDate),
      feedback_type: buttonType,
      descriptions: description,
      details: buttonType === "assessment" ? checkBoxes : "",
      created_by: userEmail
    };

    saveReport(
      payload,
      (res) => {
        if (res?.row_no) {
          let sendFeedbackPayload = {
            receiver_email: receiverEmail,
            subject: "In-season Insights Feedback",
            body: "In-season Insights User's Feedback Details",
            page_url: deepLinkUrl,
            name: firstName + " " + lastName,
            email: userEmail,
            current_trial: selectedOption,
            flight_date: DateFormatingForReportingModal(reportingFlightDate),
            feedback_type: buttonClicked,
            details: details,
            descriptions: description,
            row_no: res?.row_no,
            submitted_datetime: DateFormatingForReportingModal(new Date())
          };
          sendReport(
            sendFeedbackPayload,
            (res) => {
              if (res?.status_code == 200) {
                setShowReportingModal(false);
                setSuccessModal(true);
              }
            },
            (err) => {
              console.log("err", err);
            }
          );
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const handleClose = () => {
    setShowReportingModal(false);
  };

  const handleButtonClick = (value) => {
    setDescription("");
    setSearchText("");
    setCheckBoxes([]);
    setFilteredAssessments(flightAssesments?.categories);
    setTextAreaDisabled(true);
    setButtonClicked(value);
    if (value === "Others") {
      setTextAreaDisabled(false);
    }
  };

  const handleSearchClear = () => {
    setSearchText("");
    setFilteredAssessments(flightAssesments?.categories);
  };

  return (
    <>
      <Modal
        className="report-modal"
        show={showReportingModal}
        onHide={handleClose}
        backdrop="true"
        centered>
        <Modal.Header>
          <Modal.Title>
            <span className="report-title">Report a plot issue</span>
            <X
              data-testid="clear-search"
              role="button"
              width={24}
              height={24}
              color="#0D1A40"
              onClick={handleClose}
              className="report-x-button"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="report-modal-body">
          <div className="mb-3">
            <span className="report-modal-text">
              <strong>Trial </strong>
              <span className="font-weight-400">{selectedOption}</span>
            </span>
            <span className="ml-4 report-modal-text">
              <strong>Plot Number</strong>{" "}
              <span className="font-weight-400">{reportingPlotId}</span>
            </span>

            <span className="ml-4 report-modal-text">
              <strong>Flight Date</strong>{" "}
              <span className="font-weight-400">{reportingFlightDate?.split(" ")[0]}</span>
            </span>
          </div>
          {buttonClicked !== "Assessments" && buttonClicked !== "Others" && (
            <div className="font-weight-400 font-size-small font-family-helvetica deep-cove-color mb-3">
              <span>Please access the</span>{" "}
              <span
                className="leaf-text cursor-pointer"
                onClick={() => {
                  setOpenTrialReportModal(true), setShowReportingModal(false);
                }}>
                Trial Reporting feature{" "}
              </span>
              <span> if you want to report any trial related feedback and issues.</span>
            </div>
          )}
          <div className="mb-3">
            {buttonClicked !== "Assessments" && buttonClicked !== "Others" && (
              <span className="report-modal-text">
                <InfoCircleFill fill="#FFB71E" height={20} width={20} /> &nbsp;&nbsp;Select one
                option to enable description
              </span>
            )}
          </div>

          <div className="mb-3 mt-3">
            <Button
              onClick={() => handleButtonClick("Assessments")}
              className="ml-3 report-modal-buttons"
              style={{
                backgroundColor: buttonClicked === "Assessments" ? "#E08330" : "#EDEDED",
                color: buttonClicked === "Assessments" ? "#FFFFFF" : "#0D1A40"
              }}>
              Assessments
            </Button>
            <Button
              onClick={() => handleButtonClick("Others")}
              className="ml-3 report-modal-buttons"
              style={{
                backgroundColor: buttonClicked === "Others" ? "#E08330" : "#EDEDED",
                color: buttonClicked === "Others" ? "#FFFFFF" : "#0D1A40"
              }}>
              Others
            </Button>
          </div>
          {buttonClicked === "Assessments" && (
            <div className="mb-3">
              <div className="d-flex justify-content-center">
                <InputGroup style={{ width: "459px", position: "relative", marginRight: "26px" }}>
                  <FormControl
                    type="text"
                    placeholder="Search for ratings"
                    className="report-search"
                    value={searchText}
                    onChange={onChangeForFiltering}
                  />
                  {searchText ? (
                    <X
                      data-testid="clear-search"
                      onClick={handleSearchClear}
                      style={{ top: "21px" }}
                      className="assessment-search-icon"
                      width={15}
                      height={15}
                    />
                  ) : (
                    <Search
                      onClick={() => {}}
                      style={{ top: "21px" }}
                      className="assessment-search-icon"
                      width={15}
                      height={15}
                    />
                  )}
                </InputGroup>
              </div>

              <div className="ml-2 mt-3">
                {checkBoxes?.length === 0 && !assesmentSearchError && (
                  <span className="report-modal-text">
                    <InfoCircleFill fill="#FFB71E" height={20} width={20} /> &nbsp;&nbsp;Please
                    select at least one assessment to enable description
                  </span>
                )}
              </div>

              <div className="text-right pr-3 pt-2 mobileClearAll">
                {!assesmentSearchError && (
                  <span
                    className="ass-clear-all-position"
                    style={{ float: "unset", fontSize: "14px" }}
                    onClick={() => setCheckBoxes([])}>
                    DESELECT ALL
                  </span>
                )}
              </div>
              {!assesmentSearchError ? (
                <div style={{ maxHeight: "150px", overflow: "scroll" }}>
                  {filteredAssessments &&
                    Object.keys(filteredAssessments)
                      .sort((a, b) => {
                        if (a?.toLowerCase() < b?.toLowerCase()) {
                          return -1;
                        }
                        if (a?.toLowerCase() > b?.toLowerCase()) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((type) => {
                        const filterArray = filteredAssessments[type];

                        return filterArray?.length > 0 ? (
                          <div style={{ margin: "0px 10px", borderBottom: "1px solid #ededed" }}>
                            <div className="d-flex justify-content-between assessment-group px-3">
                              <span className="report-drone-manual-img">{type}</span>
                            </div>
                            {filterArray?.map((title) => {
                              const typeCheck = title;

                              return (
                                <div
                                  className="d-flex justify-content-between align-items-center px-3"
                                  style={{
                                    padding: "5px"
                                  }}
                                  key={typeCheck}>
                                  <div>
                                    <label>
                                      <input
                                        type="checkbox"
                                        name="options"
                                        className="assessment-input"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setCheckBoxes([...checkBoxes, typeCheck]);
                                          } else {
                                            setCheckBoxes(
                                              checkBoxes.filter((item) => item !== typeCheck)
                                            );
                                          }
                                        }}
                                        checked={checkBoxes.includes(typeCheck)}
                                      />
                                      <span className="report-assessment-names">
                                        {typeCheck.split("v:")[0]}
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : null;
                      })}
                </div>
              ) : (
                <div className="d-flex ml-3 justify-content-center align-items-center mb-3">
                  <span className="report-modal-no-results-text">No results found</span>
                </div>
              )}
            </div>
          )}
          <div
            className="mt-3 description-textarea"
            style={{
              opacity: textAreaDisabled ? 0.5 : 1
            }}>
            <label className="mb-2 " style={{ fontFamily: "Helvetica", fontSize: "12px" }}>
              <strong> Description</strong>
            </label>
            <textarea
              rows="4"
              cols="45"
              placeholder="Provide your details here..."
              className="form-control"
              style={{
                resize: "none",
                fontSize: "12px",
                color: "#0D1A40",
                borderRadius: "10px"
              }}
              value={description}
              disabled={textAreaDisabled === true}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button onClick={handleClose} className="report-modal-cancel">
            <span className="deep-cove-color">CANCEL</span>
          </button>
          <button
            onClick={handleSubmit}
            className="report-modal-submit"
            style={{
              opacity: description === "" ? 0.3 : 1
            }}
            disabled={description === ""}>
            <span>SUBMIT</span>
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="success-modal"
        show={successModal}
        // onHide={() => setSuccessModal(false)}
        centered>
        <Modal.Body style={{ width: "560px" }}>
          <div className="d-flex flex-column justify-content-center align-items-center py-5">
            <CheckCircleFill color="#2EB800" height={48} width={48} />
            <div className="pt-3">
              <span className="font-24 deep-cove-color">Issue has been reported successfully</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            onClick={() => {
              setSuccessModal(false);
            }}
            className="success-modal-ok">
            <span>OK</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ReportModal.propTypes = {
  reportingPlotId: propTypes.any,
  reportingFlightDate: propTypes.any,
  selectedOption: propTypes.any,
  assessmentDateType: propTypes.any,
  showReportingModal: propTypes.any,
  setShowReportingModal: propTypes.any,
  setSuccessModal: propTypes.any,
  successModal: propTypes.any,
  setOpenTrialReportModal: propTypes.any,
  deepLinkUrl: propTypes.any
};
