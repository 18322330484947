import { useState, useRef, useEffect } from "react";
import propTypes from "prop-types";
import syngentaLogo from "../assests/Styles/assets/logo.png";
import mobileLogo from "../assests/Styles/assets/mobileLogo.png";

import { Button } from "react-bootstrap";
import "../assests/Styles/mobileTopBar.css";
import "../assests/Styles/color.css";
import "../assests/Styles/common.css";
import logout from "../assests/Styles/assets/logout.svg";
import orangeDrone from "../assests/Styles/assets/orangeDrone.svg";
import whiteDrone from "../assests/Styles/assets/whiteDrone.svg";
// import ToggleSwitch from "./ToggleSwitch";
import mobileTrailSummaryIcon from "../assests/Styles/assets/mobileTrailSummaryIcon.svg";
import { X, ChatLeftText, XLg, InfoCircle, ExclamationTriangleFill } from "react-bootstrap-icons";
import vectorNewIcon from "../assests/Styles/assets/vectorNewIcon.svg";
import shapeNewIcon from "../assests/Styles/assets/ShapeNewIcon.svg";
import FeedbackForm from "./FeedbackForm";
import { useMediaQuery } from "react-responsive";
import AddRemovePeople from "./AddRemovePeople";
import { chatsGroupsList } from "../services/API";
import ChatList from "./ChatList";
import TrialReportModal from "./TrialReportModal";
import mobileThreeDotsIcon from "../assests/Styles/assets/mobileThreeDotsIcon.svg";
import { useCookies } from "react-cookie";
import { useMsal } from "@azure/msal-react";
import { signOut } from "@aws-amplify/auth";
import { Amplify } from "aws-amplify";
import awsConfig from "../services/amplifyconfiguration.json";

Amplify.configure(awsConfig);

export default function MobileTopBar({
  protocolName,
  viewAPIData,
  cropData,
  selectedOption,
  handleTrialSummary,
  trial,
  isEditViewer = false,
  protocolTrials,
  collectionId,
  trialType,
  customMobilePortrait,
  customMobileLandscape,
  selectedFlightDate,
  accounts,
  instance,
  state,
  submitForm,
  assessmentDateType,
  flightCollectionId,
  setOpenTrialReportModal,
  openTrialReportModal,
  deepLinkUrl
}) {
  const [cookies] = useCookies(["accessToken", "userEmail"]);
  const emailId = cookies?.userEmail;
  const emailSplit = emailId?.split(".");
  const [profileClick, setProfileClick] = useState(false);
  const dropdownRef = useRef(null);
  const profileIconRef = useRef(null);
  const [infoIcon, setInfoIcon] = useState(false);
  const infoIconRef = useRef(null);
  const infoIconContainerRef = useRef(null);
  const handleprofileIconClose = () => setProfileClick(false);
  const handleClose = () => setInfoIcon(false);
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const [showNewDiv, setShowNewDiv] = useState(false);
  const [showChatDiv, setShowChatDiv] = useState(false);
  const newDivRef = useRef(null);
  const newIconRef = useRef(null);
  const chatDivRef = useRef(null);
  const addPeopleModalRef = useRef(null);
  const accessToken = cookies?.accessToken;
  const [groupsList, setGroupsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newChat, setNewChat] = useState(false);
  const [newGroupCreated, setNewGroupCreated] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [addPeopleChatList, setAddPeopleChatList] = useState(false);
  const [deleteChatList, setDeleteChatList] = useState(false);
  const [addPeople, setAddPeople] = useState(false);
  const [mobileThreeDots, setMobileThreeDots] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const threeDotsRef = useRef(null);
  const threeDotsMenuRef = useRef(null);
  const tooltipRef = useRef(null);

  const { instance: instance1 } = useMsal();
  const handleLogout = async() => {
    instance1.logoutRedirect({
      postLogoutRedirectUri: "/app",
      mainWindowRedirectUri: "/app" // redirects the top level app after logout
    });
    sessionStorage.removeItem("msal.interaction.status");
    try {
      await signOut();
      sessionStorage.clear();
      localStorage.clear();
    } catch (err) {
      console.log("Error signing out: ", err);
    }
  };
  const handleTrials = (trialDetails) => {
    window.open(`/app/viewer?trial=${trialDetails?.trial_name}`, "_blank");
  };

  let isCheckSelectedOption =
    selectedOption !== "All trials" && selectedOption !== "No trials" ? selectedOption : " ";
  let isCheckTrial = trial !== "All trials" && trial !== "No trials" ? trial : " ";

  const truncateCombinedText = (text2, maxLength) => {
    const combinedText = ` ${text2}`.trim();
    if (combinedText.length > maxLength) {
      return combinedText.substring(0, maxLength);
    }
    return combinedText;
  };

  const siteName = viewAPIData?.site_name || "";
  const blockName = viewAPIData?.block_name || "";
  const truncatedText = truncateCombinedText(blockName, 26);
  const checkedTrialClass = customMobilePortrait ? "col-6" : "col-8 ";
  const prtocolAndCropData = cropData.length > 0 || protocolName;

  const [isShowFeedback, setIsShowFeedback] = useState(false);

  const groupLists = () => {
    const chatPayload = {
      access_token: accessToken,
      topic: trial
    };
    setLoading(true);
    chatsGroupsList(chatPayload, (res) => {
      let list = [];
      res?.value.forEach((item, index) => {
        list.push({
          id: item?.id,
          name: item?.topic,
          teamsLink: item?.webUrl,
          children: Object.entries(item?.members).map(([key, value]) => ({
            id: index,
            name:
              key === "general_user"
                ? "Others"
                : key
                    .split("_")
                    .map((word) => word?.charAt(0).toUpperCase() + word?.slice(1))

                    .join(" "),
            children: value.map((obj) => ({
              id: obj.id,
              name: obj.email,
              chatGroupId: item?.id
            }))
          }))
        });
      });

      setGroupsList(list);

      setLoading(false);
    });
  };

  useEffect(() => {
    groupLists();
    setNewGroupCreated(false);
  }, [trial, newGroupCreated]);

  useEffect(() => {
    if (deleteChatList === true) {
      groupLists();

      setDeleteChatList(false);
    }
  }, [deleteChatList]);

  useEffect(() => {
    if (addPeopleChatList === true) {
      groupLists();

      setAddPeopleChatList(false);
    }
  }, [addPeopleChatList]);

  useEffect(() => {
    if (showChatDiv === true) {
      groupLists();
    }
  }, [showChatDiv]);

  const handleOpenFeedback = () => {
    setIsShowFeedback(true);
  };

  const handleCloseFeedback = () => {
    setIsShowFeedback(false);
  };

  useEffect(() => {
    const handleClickOutside1 = (event) => {
      if (
        (chatDivRef.current && !chatDivRef.current.contains(event.target)) ||
        !chatDivRef.current
      ) {
        setShowChatDiv(false);
        setNewChat(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideInfoIcon = (event) => {
      if (
        infoIconRef.current &&
        !infoIconRef.current.contains(event.target) &&
        !infoIconContainerRef?.current?.contains(event.target)
      ) {
        setInfoIcon(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutsideInfoIcon);
    return () => {
      window.removeEventListener("mousedown", handleClickOutsideInfoIcon);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!tooltipVisible && addPeople) {
        if (
          newIconRef.current &&
          !newIconRef.current.contains(event.target) &&
          ((newDivRef.current && !newDivRef.current.contains(event.target)) ||
            !newDivRef.current) &&
          ((addPeopleModalRef.current && !addPeopleModalRef.current.contains(event.target)) ||
            !addPeopleModalRef.current)
        ) {
          setShowNewDiv(false);
          setShowChatDiv(true);
          setNewChat(true);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipVisible]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (tooltipRef.current && !tooltipRef.current.contains(event.target)) ||
        !tooltipRef.current
      ) {
        setTooltipVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!tooltipVisible && !addPeople && showNewDiv) {
        if (
          newIconRef.current &&
          !newIconRef.current.contains(event.target) &&
          ((newDivRef.current && !newDivRef.current.contains(event.target)) || !newDivRef.current)
        ) {
          setShowNewDiv(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipVisible, addPeople, showNewDiv]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !profileIconRef?.current?.contains(event.target)
      ) {
        setProfileClick(false);
      }
      if (
        threeDotsRef.current &&
        !threeDotsRef.current.contains(event.target) &&
        !threeDotsMenuRef?.current?.contains(event.target)
      ) {
        setMobileThreeDots(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleButtonClick = () => {
    setProfileClick(!profileClick);
  };

  const handleCreateNewChat = () => {
    setShowChatDiv(true);
    setShowNewDiv(false);
    setNewChat(true);
  };

  const toggleNewDiv = () => {
    setShowNewDiv(!showNewDiv);
    setShowChatDiv(true);
    setMobileThreeDots(false);
  };

  const handleCloseTooltip = () => {
    setTooltipVisible(false);
    setShowNewDiv(true);
  };
  return (
    <div
      className="row"
      style={{
        flexWrap: "unset",
        height: "60px",
        backgroundColor: "#0D1A40",
        zIndex: "999",
        position: "fixed",
        left: 0,
        right: 0,
        top: 0
      }}>
      <div
        className={`${
          customMobilePortrait
            ? "col-2  d-flex align-items-center justify-content-center"
            : "col-1  d-flex align-items-center  pr-0"
        }`}>
        <div className="logo" id="viewer-link">
          <a className="logo" href="/app/" id="viewer-link">
            <div className="align-items-center" style={{ textAlign: "center" }}>
              <img
                src={customMobileLandscape ? mobileLogo : syngentaLogo}
                href="#"
                alt="syngenta"
                style={{
                  marginLeft: customMobilePortrait ? "20px" : "8px",
                  cursor: "pointer"
                }}
              />
            </div>
          </a>
        </div>
      </div>
      <div className={checkedTrialClass} style={{ marginLeft: customMobileLandscape && "8px" }}>
        <div>
          {!isEditViewer && (
            <div
              className="white-color font-weight-700 font-size-medium font-family-Helvetica"
              style={{
                marginTop: customMobilePortrait ? "0px" : "2px"
              }}>
              {isCheckSelectedOption ? isCheckSelectedOption : isCheckTrial}
            </div>
          )}
        </div>

        <div className="white-color font-weight-400 font-size-small font-family-Helvetica">
          {siteName}
        </div>
        <div className="white-color font-weight-400 font-size-small font-family-Helvetica">
          {truncatedText}
        </div>
      </div>

      <div
        className={
          customMobilePortrait
            ? "col-4 d-flex align-items-center justify-content-center"
            : "col-3 d-flex align-items-center justify-content-center"
        }>
        <div>
          <>
            {prtocolAndCropData ? (
              <>
                {selectedOption !== "All trials" && selectedOption !== "No trials" && (
                  <div onClick={() => setInfoIcon(!infoIcon)} ref={infoIconRef}>
                    <InfoCircle data-testid="info-circle" height={24} width={24} color="white" />
                  </div>
                )}
              </>
            ) : null}
          </>
        </div>

        <div
          className="mobile-three-dots"
          onClick={() => setMobileThreeDots(!mobileThreeDots)}
          ref={threeDotsRef}>
          <img src={mobileThreeDotsIcon} alt="" />
        </div>

        <div>
          <div
            style={{
              marginRight: customMobilePortrait ? "6px" : ""
            }}>
            <button
              className="ml-auto"
              style={{
                border: "none",
                backgroundColor: "#006D82",
                height: "24px",
                width: "24px",
                borderRadius: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontSize: "12px"
              }}
              ref={profileIconRef}
              onClick={handleButtonClick}>
              {emailSplit && emailSplit?.length > 1
                ? emailSplit?.[0].charAt(0).toUpperCase() + emailSplit?.[1].charAt(0).toUpperCase()
                : emailSplit?.[0].charAt(0).toUpperCase()}
            </button>

            {profileClick && (
              <div
                ref={dropdownRef}
                style={{
                  position: "fixed",
                  right: "0",
                  top: "60px",
                  backgroundColor: "#0D1A40",
                  zIndex: "99999",
                  minWidth: isMobile ? "101vw" : "388px"
                }}>
                <div style={{ position: "absolute", zIndex: 9, right: "8px", marginTop: "4px" }}>
                  <X color="black" height={24} width={24} onClick={handleprofileIconClose} />
                </div>
                <ul className="list-group">
                  <li style={{ height: "85px" }} className="list-group-item">
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "31px",
                        alignItems: "center"
                      }}>
                      <span>
                        <img src={shapeNewIcon} alt="shapeNewIcon" height={24} width={24} />
                      </span>
                      <span
                        style={{
                          fontSize: ".875rem"
                        }}>
                        {" "}
                        {emailId}
                      </span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <button className="mobileTop-feedback" onClick={handleOpenFeedback}>
                      <div className="mobileTab-vector">
                        <span>
                          <img
                            src={vectorNewIcon}
                            alt="vectorNewIcon"
                            style={{ width: "20px", height: "20px", margin: "2px" }}
                          />
                        </span>
                        <span style={{ fontWeight: 400, fontSize: "14px", color: "#000000" }}>
                          {" "}
                          Feedback
                        </span>
                      </div>
                    </button>
                  </li>
                  {/* <li
                    className="list-group-item"
                    style={{
                      borderBottom: "1px solid #343E5C",
                      backgroundColor: "#0D1A40",
                      paddingLeft: "33px"
                    }}>
                    <ToggleSwitch
                      trial={selectedOption}
                      collectionId={collectionId}
                      trialType={trialType}
                      customMobilePortrait={customMobilePortrait}
                      customMobileLandscape={customMobileLandscape}
                    />
                  </li> */}
                  <li
                    className="list-group-item"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#FFFFFF"
                    }}>
                    <img
                      src={logout}
                      alt="logout"
                      style={{ width: "18px", height: "18px", margin: "3px", fontWeight: "700" }}
                    />
                    <button className="logout-Mobile" onClick={handleLogout}>
                      <span style={{ fontWeight: "700" }}>&nbsp;LOGOUT</span>
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      {groupsList?.length > 0 && showNewDiv && (
        <div
          ref={newDivRef}
          style={{
            position: "absolute",
            right: "0px",
            top: "60px",
            minWidth: customMobilePortrait ? "-webkit-fill-available" : "430px",
            // width: "max-content",
            height: "calc(100vh - 60px)",
            backgroundColor: "white",
            zIndex: 9999,
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"
          }}>
          <div className="d-flex justify-content-between pt-3">
            <span
              className="com-heading"
              style={{ paddingLeft: customMobilePortrait ? "35px" : "" }}>
              Chat (s)
            </span>
            <span style={{ paddingRight: "35px" }}>
              <X
                className="cursor-pointer"
                height={22}
                width={22}
                onClick={() => setShowNewDiv(false)}
              />
            </span>
          </div>
          <div className="mobile-chat-text" onClick={handleCreateNewChat}>
            <div className="new-chat-text">NEW CHAT</div>
          </div>
          <div
            style={{
              marginTop: "8px",
              // maxHeight: "460px",
              overflowY: "scroll",
              marginRight: "8px"
            }}>
            <AddRemovePeople
              addPeopleModalRef={addPeopleModalRef}
              trail={trial}
              groupsList={groupsList}
              tooltipVisible={tooltipVisible}
              setTooltipVisible={setTooltipVisible}
              onCloseTooltip={handleCloseTooltip}
              setDeleteUser={setDeleteUser}
              deleteUser={deleteUser}
              setAddPeopleChatList={setAddPeopleChatList}
              addPeopleChatList={addPeopleChatList}
              loading={loading}
              setDeleteChatList={setDeleteChatList}
              customMobilePortrait={customMobilePortrait}
              customMobileLandscape={customMobileLandscape}
              addPeople={addPeople}
              setAddPeople={setAddPeople}
              setShowChatDiv={setShowChatDiv}
              setShowNewDiv={setShowNewDiv}
            />
          </div>
        </div>
      )}
      {((groupsList?.length == 0 && showChatDiv) || newChat) && (
        <div
          className="mobile-accordion-content"
          ref={chatDivRef}
          style={{
            position: "absolute",
            right: "16px",
            top: "60px",
            minWidth: customMobilePortrait ? "-webkit-fill-available" : "430px",
            // width: "max-content",
            height: "calc(100vh - 60px)",
            backgroundColor: "white",
            zIndex: 9999,
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"
          }}>
          <div
            className="mobile-chat-heading-text"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginRight: "15px"
            }}>
            New Chat
            <XLg
              role="button"
              width={18}
              height={18}
              color="#0D1A40"
              onClick={() => {
                setShowChatDiv(false);
                setNewChat(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="nested-dropdown-design">
            <ChatList
              addPeopleModalRef={addPeopleModalRef}
              trail={trial}
              setNewGroupCreated={setNewGroupCreated}
              groupsList={groupsList}
              groupLists={groupLists}
              customMobilePortrait={customMobilePortrait}
              customMobileLandscape={customMobileLandscape}
              addPeople={addPeople}
              setAddPeople={setAddPeople}
              setShowChatDiv={setShowChatDiv}
              setShowNewDiv={setShowNewDiv}
              setNewChat={setNewChat}
            />
          </div>
        </div>
      )}

      {infoIcon && (
        <div
          className="infoIconContainer"
          ref={infoIconContainerRef}
          // className="row"
          style={{
            position: "absolute",
            backgroundColor: "#ffffff",
            top: "60px",
            left: customMobilePortrait && "15px",
            right: customMobilePortrait ? "0px" : "15px",
            width: customMobilePortrait ? "100%" : "430px",
            height: customMobilePortrait ? "auto" : "calc(100vh - 60px)",
            // maxHeight: customMobilePortrait ? "" : "-webkit-fill-available",
            overflow: "auto",
            paddingBottom: "10px"
          }}>
          <div>
            <div className="d-flex" style={{ padding: "15px", borderBottom: "1px solid #343E5C" }}>
              <div>
                <span className="deep-cove-color font-weight-700 font-size-small font-family-Helvetica">
                  Crops
                </span>
                <span
                  style={{
                    position: "absolute",
                    right: customMobilePortrait ? "50px" : "20px",
                    marginTop: "6px"
                  }}>
                  <X
                    data-testid="info-close-button"
                    color="black"
                    height={24}
                    width={24}
                    onClick={handleClose}
                  />
                </span>
                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  {cropData.map((item) => (
                    <li
                      key={item}
                      className="deep-cove-color font-weight-400 font-size-small font-family-Helvetica">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              style={{ borderBottom: "1px solid #343E5C", padding: "15px" }}
              onClick={handleTrialSummary}>
              <span>
                <img src={mobileTrailSummaryIcon} />
              </span>
              <span
                style={{
                  color: "#E08330",
                  fontSize: "12px",
                  fontFamily: "Helvetica",
                  fontWeight: 400,
                  paddingLeft: "10px"
                }}>
                Trial Summary
              </span>
            </div>
            {trialType !== "manual" && (
              <>
                <div style={{ borderBottom: "1px solid #343E5C", padding: "15px" }}>
                  <div className="deep-cove-color font-weight-400 font-size-small font-family-Helvetica">
                    {siteName}
                  </div>
                  <div className="deep-cove-color font-weight-400 font-size-small font-family-Helvetica">
                    {blockName}
                  </div>
                </div>
              </>
            )}
            <div className="d-flex" style={{ borderBottom: "1px solid #343E5C", padding: "15px" }}>
              {protocolName && (
                <div className="deep-cove-color font-weight-700 font-size-small font-family-Helvetica">
                  {protocolName}
                </div>
              )}
            </div>

            {location.pathname.indexOf("ploteditor") === -1 && (
              <div className="d-flex mt-2">
                <div
                  className="deep-cove-color font-weight-700 font-size-small font-family-Helvetica"
                  style={{
                    padding: "10px 15px"
                  }}>
                  Protocol Trials
                </div>
              </div>
            )}
            {protocolTrials && location.pathname.indexOf("ploteditor") === -1 && (
              <>
                {protocolTrials?.map((item) => (
                  <Button
                    key={item?.trial_name} // Add key prop
                    disabled={item?.trial_name === trial}
                    onClick={() => handleTrials(item)}
                    className="trialButtons"
                    style={{
                      backgroundColor: item?.trial_name === trial ? "#E08330" : "#EDEDED",
                      color: item?.trial_name === trial ? "#FFFFFF" : "#0D1A40",
                      opacity: item?.trial_name === trial && 1,
                      marginLeft: "15px"
                    }}>
                    {item?.trial_name}
                    {item?.has_drone_assessments ? (
                      <img
                        key={item?.trial_name} // Add key prop
                        src={item?.trial_name === trial ? whiteDrone : orangeDrone}
                        alt=""
                        style={{ marginLeft: "10px" }}
                      />
                    ) : null}
                  </Button>
                ))}
              </>
            )}
          </div>
        </div>
      )}

      {mobileThreeDots && (
        <div className="mobile-three-dots-menu" ref={threeDotsMenuRef}>
          <div
            className="mobile-chat-div"
            data-testid="mobile-chat-container"
            onClick={toggleNewDiv}>
            <span className="ml-2 mr-2" data-testid="chat-button">
              <ChatLeftText
                data-testid="chat-button"
                ref={newIconRef}
                color="#0D1A40"
                width={16}
                height={16}
              />
            </span>
            <span className="font-weight-400 font-size-small font-family-Helvetica deep-cove-color">
              Chat
            </span>
          </div>
          <div
            className="mobile-report-an-issue"
            onClick={() => {
              setOpenTrialReportModal(true);
              setMobileThreeDots(false);
            }}>
            <span className="mr-2">
              <ExclamationTriangleFill height={16} width={16} />
            </span>
            <span className="font-weight-400 font-size-small font-family-Helvetica deep-cove-color">
              Report an Issue
            </span>
          </div>
        </div>
      )}

      <div>
        <FeedbackForm
          handleFeedbackClose={handleCloseFeedback}
          isShowFeedback={isShowFeedback}
          handleCloseFeedback={handleCloseFeedback}
          isDesktop={true}
          accounts={accounts}
          instance={instance}
          state={state}
          submitForm={submitForm}
          collectionId={collectionId}
          selectedTrail={selectedOption}
          selectedFlightDate={selectedFlightDate}
        />
      </div>

      <div>
        {(openTrialReportModal || successModal) && (
          <TrialReportModal
            openTrialReportModal={openTrialReportModal}
            setOpenTrialReportModal={setOpenTrialReportModal}
            selectedOption={selectedOption}
            selectedFlightDate={selectedFlightDate}
            assessmentDateType={assessmentDateType}
            successModal={successModal}
            setSuccessModal={setSuccessModal}
            trialType={trialType}
            flightCollectionId={flightCollectionId}
            customMobilePortrait={customMobilePortrait}
            customMobileLandscape={customMobileLandscape}
            deepLinkUrl={deepLinkUrl}
          />
        )}
      </div>
    </div>
  );
}

MobileTopBar.propTypes = {
  protocolName: propTypes.any,
  viewAPIData: propTypes.any,
  cropData: propTypes.any,
  pestData: propTypes.any,
  selectedOption: propTypes.any,
  trial: propTypes.any,
  selectedFlightDate: propTypes.any,
  isEditViewer: propTypes.any,
  protocolTrials: propTypes.any,
  collectionId: propTypes.any,
  trialType: propTypes.any,
  handleTrialSummary: propTypes.any,
  customMobilePortrait: propTypes.any,
  customMobileLandscape: propTypes.any,
  accounts: propTypes.any,
  instance: propTypes.any,
  state: propTypes.any,
  submitForm: propTypes.any,
  assessmentDateType: propTypes.any,
  flightCollectionId: propTypes.any,
  setOpenTrialReportModal: propTypes.any,
  openTrialReportModal: propTypes.any,
  deepLinkUrl: propTypes.any
};
