import { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import propTypes from "prop-types";
import "../assests/Styles/newviewer.css";
import "../assests/Styles/color.css";
import { callMsGraph, sendFeedbackSmtpAPI } from "../services/API";
import { useMsal } from "@azure/msal-react";
import { Spinner } from "../services/Common";
import { useCookies } from "react-cookie";
export default function FeedbackForm({
  handleFeedbackClose,
  isShowFeedback,
  selectedTrail,
  // eslint-disable-next-line no-unused-vars
  selectedFlightDate,
  collectionId,
  isMobile,
  handleCloseFeedback,
  isTablet
}) {
  const [cookies, setCookie] = useCookies(["userEmail"]);
  const [name, setName] = useState();
  const [email, setEmail] = useState(cookies?.userEmail);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isValid = Boolean(name && email && message);
  const { instance, accounts } = useMsal();

  const handleSubmit = async (e) => {
    setIsSubmitted(true);
    e.preventDefault();
    const date = new Date();
    const isoString = date.toISOString(); // "2022-06-23T14:40:00.000Z"
    const datePart = isoString.split("T")[0]; // "2022-06-23"
    const timePart = isoString.split("T")[1].substring(0, 5); // "14:40"
    const feedbackDetails = {
      receiver_email: "dlglobalinseasoninsights@syngenta.com",
      subject: "In-season Insights Feedback",
      body: "In-season Insights User's Feedback Details",
      page_url: window.location.href,
      name: name,
      descriptions: message,
      email: email,
      current_trial: selectedTrail,
      collection_id: collectionId || "",

      submitted_datetime: `${datePart}-${timePart}`
    };
    sendFeedbackSmtpAPI(
      feedbackDetails,
      () => {
        handleFeedbackClose();
        setIsSubmitted(false);
        setMessage("");
      },
      (error) => {
        setIsSubmitted(false);
        console.log("error", error);
      }
    );
  };

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    if (isShowFeedback) {
      setLoading(true);
      const request = {
        scopes: ["User.Read"],
        account: accounts[0]
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance
        .acquireTokenSilent(request)
        .then(async (response) => {
          setCookie("idToken", response.idToken, 1);
          setCookie("accessToken", response.accessToken, 1);
          callMsGraph(response.accessToken).then((responseUserDetails) => {
            sessionStorage.setItem("firstName", responseUserDetails?.givenName);
            sessionStorage.setItem("lastName", responseUserDetails?.surname);
            setName(`${responseUserDetails?.givenName} ${responseUserDetails?.surname}`);
            setLoading(false);
          });
        })
        .catch(() => {
          instance.acquireTokenRedirect(request).then((response) => {
            setCookie("idToken", response.idToken, 1);
          });
          setLoading(false);
        });
      if (accounts?.[0]) {
        setCookie("userEmail", accounts?.[0]?.username, 1);
      }
    }
  }, [isShowFeedback]);
  const mobileFeedback = isMobile ? "feedback" : "desktopFeedbackModal";
  const modalClassName = `${loading ? "content-loading" : ""} ${isTablet ? "tabletFeedback" : mobileFeedback
    }`;
  const formMobileClass = isMobile ? "mb-1 py-1" : "pb-2 mx-1 mb-1";
  const formNameClass = isTablet ? "pb-2 mx-1 mb-2" : formMobileClass;
  return (
    <Modal
      show={isShowFeedback}
      style={{ border: "2px solid red!important" }}
      centered
      onHide={handleCloseFeedback}
      className={modalClassName}>
      {loading && (
        <div className="send-feedback-popup trial-summary-popup center-spinner">
          <Spinner animation="border" className="custom-spinner" />
        </div>
      )}
      <Modal.Header className="test">
        <Modal.Title className="feedbackTitle deep-cove-color">
          Send feedback on In-Season Insights
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "14px" }}>
        <div>
          <div className="mb-2 deep-cove-color">
            Have any questions or thoughts on how we improve In-Season Insights, send us a note here
            and we will take actions or follow-up with you to get more details.
          </div>
          <div className="mb-2 deep-cove-color">
            To send us more detailed information (e.g. screenshots), email{" "}
            <span className="light-orange-color"> DLGlobalInseasoninsights@syngenta.com</span>
          </div>
          <div className={isTablet ? "mb-2 deep-cove-color" : " deep-cove-color"}>
            Drone pilots who need changes to plot boundaries, data outputs, or other operational
            needs, please use the standard{" "}
            <a
              href="https://airtable.com/appAilMTp21Dedz3r/shrmbDYgiFf0K7WQx"
              target="_blank"
              rel="noreferrer"
              className="feedback-opslink">
              Ops form
            </a>{" "}
            to connect with the DDC team.
          </div>
          <br />
          <div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className={formNameClass} controlId="name">
                <Form.Label
                  className="mb-1 deep-cove-color"
                  style={{
                    fontWeight: "bold",
                    fontSize: isMobile || isTablet ? "16px" : "14px",
                    fontFamily: "Helvetica"
                  }}>
                  Your Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Example : Allisa Kriss"
                  className="deep-cove-color"
                  value={name}
                  style={{ borderRadius: "10px" }}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className={formNameClass} controlId="email">
                <Form.Label
                  className="mb-1 deep-cove-color "
                  style={{
                    fontWeight: "bold",
                    fontSize: isMobile || isTablet ? "16px" : "14px",
                    fontFamily: "Helvetica"
                  }}>
                  Your Email
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="allisakriss@example.com"
                  className="deep-cove-color"
                  value={email}
                  onChange={handleEmailChange}
                  style={{ borderRadius: "10px" }}
                  required
                />
              </Form.Group>
              <Form.Group
                className={formNameClass}
                style={{ marginBottom: isTablet ? "12% !important" : "" }}
                controlId="message">
                <Form.Label
                  className="mb-1 deep-cove-color"
                  style={{
                    fontWeight: "bold",
                    fontSize: isMobile || isTablet ? "16px" : "14px",
                    fontFamily: "Helvetica"
                  }}>
                  Your Message
                </Form.Label>
                <Form.Control
                  as="textarea"
                  className="deep-cove-color"
                  value={message}
                  rows={3}
                  onChange={handleMessageChange}
                  style={{ borderRadius: "10px", boxShadow: "none", border: "1px solid #ced4da" }}
                  required
                />
              </Form.Group>
              <hr style={{ marginBottom: isTablet ? "12% !important" : "" }} />
              <div
                className={isTablet || isMobile ? "py-2" : ""}
                style={{ textAlign: isMobile ? "center" : "" }}>
                <Button
                  className="text-center"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    fontWeight: "600px",
                    fontFamily: "Helvetica",
                    fontSize: "16px",
                    marginLeft: isMobile ? "0" : "35%",
                    color: "#0D1A40",
                    boxShadow: "none"
                  }}
                  onClick={handleCloseFeedback}>
                  CANCEL
                </Button>
                <Button
                  style={{
                    border: "none",
                    backgroundColor: isValid ? " #0D1A40" : "#D3D3D3",
                    borderRadius: "20px",
                    fontWeight: "600px",
                    fontFamily: "Helvetica",
                    fontSize: "16px",
                    color: "#FFFFFF"
                  }}
                  type="submit"
                  disabled={isSubmitted || !isValid}>
                  SEND
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

FeedbackForm.propTypes = {
  handleFeedbackClose: propTypes.any,
  isShowFeedback: propTypes.bool,
  selectedFlightDate: propTypes.any,
  selectedTrail: propTypes.any,
  collectionId: propTypes.any,
  handleCloseFeedback: propTypes.any,
  isMobile: propTypes.any,
  isTablet: propTypes.any
};
