import axios from "axios";
const basicURL = process.env.REACT_APP_BASE_URL;
const edcCoreUrl = process.env.REACT_APP_EDC_CORE_URL;
axios.defaults.withCredentials = true;
import { graphConfig } from "./authConfig";

export async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export function loginSyt(idToken, callback, errorCallback) {
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Credentials": true
    },
    credentials: "include"
  };
  const cred = { token: idToken };
  const baseUrl = `${basicURL}/login_syt`;
  axios
    .post(baseUrl, cred, config)
    .then((res) => {
      callback(res.status);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function siteListAPI(year, callback, errorCallback) {
  let url = `${basicURL}/api/sites?year=${year}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function trailListAPI(year, callback, errorCallback) {
  let url = `${basicURL}/api/trials?year=${year}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function protocolListAPI(year, callback, errorCallback) {
  let url = `${basicURL}/api/protocols?year=${year}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function collectionCountAPI(selectedYear, callback, errorCallback) {
  let url = `${basicURL}/api/collection_new?year=${selectedYear}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function yearsAPI(callback, errorCallback) {
  let url = `${basicURL}/api/years_list`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response?.data?.years);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function siteDetailsAPI(selectedYear, selectedSiteId, callback, errorCallback) {
  let url = `${basicURL}/api/site_details?year=${selectedYear}&site_id=${selectedSiteId}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response?.data);
    })
    .catch((err) => {
      console.log("site err", err);
      errorCallback(err?.message);
    });
}

export function trialDetailsAPI(selectedYear, selectedTrialId, callback, errorCallback) {
  let url = `${basicURL}/api/trial_details?year=${selectedYear}&trial_id=${selectedTrialId}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}
export function protocolDetailsAPI(selectedYear, selectedProtocolId, callback, errorCallback) {
  let url = `${basicURL}/api/protocol_details?year=${selectedYear}&protocol_id=${selectedProtocolId}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      console.log("err", err);
      errorCallback(err.message);
    });
}

export function getAllFilterData(data, token, callback, errorCallback) {
  let url = `${edcCoreUrl}/api/trialing/trials/all?page=0&size=150`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    credentials: "include"
  };
  axios
    .post(url, data, options)
    .then((response) => {
      const newdata = response.data?.payload ? response.data?.payload : response.data;
      callback(newdata);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}
export function barChartApi(data, callback, errorCallback) {
  let url = `${basicURL}/api/treatments`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}
export function saveLayoutAPI(data, callback, errorCallback) {
  let url = `${basicURL}/api/save_submit_trial_layouts`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}
export function getLayoutDetailsAPI(id, callback, errorCallback) {
  let url = `${basicURL}/api/get_layout_details?layout_id=${id}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}
export function getLayoutsList(id, trail, callback, errorCallback) {
  let url = `${basicURL}/api/get_layouts_list?block_id=${id}&trial_name=${trail}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}
export const getGeoLocationApi = async (id, callback, errorCallback) => {
  try {
    let url = `${edcCoreUrl}/api/field/${id}`;
    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "precisionsilver-602dc0e80bc5305b101f19f39fa2167c6e8d7634"
      }
    };
    const resp = await axios.get(url, options);
    callback(resp.data);
  } catch (err) {
    errorCallback(err.message);
  }
};

export function viewAPI(id, callback, errorCallback) {
  let url = `${basicURL}/api/view?collection_id=${id}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}
export function downloadApi(id, callback, errorCallback) {
  let url = `${basicURL}/api/resultsfile/${id}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function flightChartApi(data, callback, errorCallback) {
  let url = `${basicURL}/api/flightchart`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}
export function edcGetAccessToken(callback, errorCallback) {
  let url = `${basicURL}/api/edc/token`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getAllTrialSummaryData(trialId, token, callback, errorCallback) {
  let url = `${edcCoreUrl}/api/trialing/trials/${trialId}/plan`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getAllTrialAssesmentData(trialId, token, callback, errorCallback) {
  let url = `${edcCoreUrl}/api/trialing/trials/${trialId}/assessment`;

  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getCollectionResponse(collectionId, callback, errorCallback) {
  let url = `${basicURL}/api/view?collection_id=${collectionId}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response?.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getRatingDescriptions(callback, errorCallback) {
  let url = `${basicURL}/api/rating_descriptions`;
  let options = {
    method: "GET",
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response?.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getRatingDetails(trialId, callback, errorCallback) {
  let url = `${basicURL}/api/rating_details?trial=${trialId}`;
  let options = {
    method: "GET",
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response?.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getRasterLayersAPICall(id, callback, errorCallback) {
  let url = `${basicURL}/api/collection_rasterlayers?collection_id=${id}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getMeasurmentSets(id, callback, errorCallback) {
  let url = `${basicURL}/api/collection_measurementsets?collection_id=${id}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getAssessmentCategories(id, callback, errorCallback) {
  let url = `${basicURL}/api/assessment_categories?trial=${id}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data?.results);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function trailOwnerListAPI(year, callback, errorCallback) {
  let url = `${basicURL}/api/trials_by_owner?year=${year}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function protocolOwnerListAPI(year, callback, errorCallback) {
  let url = `${basicURL}/api/protocols_by_owner?year=${year}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function cropOwnerListAPI(year, callback, errorCallback) {
  let url = `${basicURL}/api/trials_by_crops?year=${year}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getTrialMeasurments(id, callback, errorCallback) {
  let url = `${basicURL}/api/measurementsets?trial=${id}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function newBarChartApi(data, callback, errorCallback) {
  let url = `${basicURL}/api/new_treatments`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getPlotInfo(trialId, callback, errorCallback) {
  let url = `${basicURL}/api/plotdetails?trial=${trialId}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function trialAutoComplete(selectedYear, callback, errorCallback) {
  let url =
    selectedYear === "All Years"
      ? `${basicURL}/api/trials`
      : `${basicURL}/api/trials?year=${selectedYear}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function newFlightChartApi(data, callback, errorCallback) {
  let url = `${basicURL}/api/new_flightchart`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getProtocolBytrial(trial, callback, errorCallback) {
  let url = `${basicURL}/api/get_protocol_by_trial?trial=${trial}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}
export function getTrialDetailsAPI(year, callback, errorCallback) {
  let url = `${basicURL}/api/search_trial_details?year=${year}`;
  let options = {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}
export function getSearchAutoSuggestion(data, callback, errorCallback) {
  let url = `${basicURL}/api/search_auto_suggestion`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}
export function getTrialsList(data, callback, errorCallback) {
  let url = `${basicURL}/api/search_trial_card_details`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function myTrialsAPI(data, callback, errorCallback) {
  let url = `${basicURL}/api/get_my_trials`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function recentTrialsAPI(data, callback, errorCallback) {
  let url = `${basicURL}/api/get_recent_trials`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function saveRecentTrialsAPI(data, callback, errorCallback) {
  let url = `${basicURL}/api/save_recent_trial`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function viewCollectionDetailsAPI(id, callback, errorCallback) {
  let url = `${basicURL}/api/collection_details?trial=${id}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function bookmarkTrialsAPI(data, callback, errorCallback) {
  let url = `${basicURL}/api/get_bookmark_trials`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function saveBookMarkTrialsAPI(data, callback, errorCallback) {
  let url = `${basicURL}/api/save_bookmark_trial`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function deleteBookMarkTrialsAPI(data, callback, errorCallback) {
  let url = `${basicURL}/api/delete_bookmark_trial`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function flightDatesAPI(data, callback, errorCallback) {
  let url = `${basicURL}/api/get_flight_dates`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function treatmentLabelAPI(trialId, callback, errorCallback) {
  let url = `${basicURL}/api/get_treatment_info?trial=${trialId}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function treatmentUserLabel(data, callback, errorCallback) {
  let url = `${basicURL}/api/treatment_label_user`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function checkTrialType(data, callback, errorCallback) {
  let url = `${basicURL}/api/check_trial_type`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}
export function getTrialProtocol(protocol, callback, errorCallback) {
  let url = `${basicURL}/api/get_trials_by_protocol?protocol=${protocol}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function saveSiteData(data, callback, errorCallback) {
  let url = `${basicURL}/api/save_recent_visited_site`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function recentlyVisitedSite(data, callback, errorCallback) {
  let url = `${basicURL}/api/drone_sites`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function deleteLayoutAPI(data, callback, errorCallback) {
  let url = `${basicURL}/api/delete_trial_layout`;
  let options = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include",
    data
  };
  axios
    .delete(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getDevices(trail, callback, errorCallback) {
  let url = `${basicURL}/api/get_trial_devices?trial=${trail}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getVariableByDevice(deviceId, callback, errorCallback) {
  let url = `${basicURL}/api/device_associated_variables?device_id=${deviceId}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getTrialDates(id, callback, errorCallback) {
  let url = `${basicURL}/api/trial_event_dates?trial=${id}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function recentlyActivityTracking(data, callback, errorCallback) {
  let url = `${basicURL}/api/user_activity_tracking`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function chatSearchUserApi(data, callback, errorCallback) {
  let url = `${basicURL}/api/search_users`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function chatTrialAssociatedMembers(data, callback, errorCallback) {
  let url = `${basicURL}/api/trial_associated_members?trial=${data.trial_name}`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function teamsChatCreate(data, callback, errorCallback) {
  let url = `${basicURL}/api/create_chat_group`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function chatsGroupsList(data, callback, errorCallback) {
  let url = `${basicURL}/api/chat_listing`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function deletePerson(data, callback, errorCallback) {
  let url = `${basicURL}/api/chat/remove_member`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function AddPeopleToChatList(data, callback, errorCallback) {
  let url = `${basicURL}/api/chat/add_member`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function sendFeedbackSmtpAPI(data, callback, errorCallback) {
  let url = `${basicURL}/api/send_feedback`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function sendWelcomeMessage(data, callback, errorCallback) {
  let url = `${basicURL}/api/chat/send_chat_message`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getPlotsForTrial(trial, callback, errorCallback) {
  let url = `${basicURL}/api/get_trial_plots?trial=${trial}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function getTreatmentReplicates(trial, callback, errorCallback) {
  let url = `${basicURL}/api/get_treatment_replicate?trial=${trial}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function checkSuplot(id, callback, errorCallback) {
  let url = `${basicURL}/api/check_trial_subplot?trial=${id}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  axios
    .get(url, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}
export function sendReport(data, callback, errorCallback) {
  let url = `${basicURL}/api/send_feedback`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}

export function saveReport(data, callback, errorCallback) {
  let url = `${basicURL}/api/save_feedback`;
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  axios
    .post(url, data, options)
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      errorCallback(err.message);
    });
}
