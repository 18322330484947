/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Modal, Button, InputGroup, FormControl } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import { Search, X, InfoCircleFill, CheckCircleFill, Paperclip } from "react-bootstrap-icons";
import {
  formatFlightDate,
  DateFormatingForReportingModal,
  customTabletDimensions
} from "../services/Common";
import { sendReport, saveReport } from "../services/API";
import AWS from "aws-sdk";
import { useCookies } from "react-cookie";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});

const s3 = new AWS.S3();

export default function TrialReportModal({
  openTrialReportModal,
  setOpenTrialReportModal,
  selectedOption,
  selectedFlightDate,
  assessmentDateType,
  successModal,
  setSuccessModal,
  trialType,
  flightCollectionId,
  customMobilePortrait,
  customMobileLandscape,
  deepLinkUrl
}) {
  const [cookies] = useCookies(["userEmail", "cognitoFirstName", "cognitoLastName"]);
  const userEmail = cookies?.userEmail;
  const firstName = cookies?.cognitoFirstName;
  const lastName = cookies?.cognitoLastName;
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [buttonClicked, setButtonClicked] = useState("");
  const [textAreaDisabled, setTextAreaDisabled] = useState(true);
  const [description, setDescription] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [userEmailPayload, setUserEmailPayload] = useState();

  const flightAssessments =
    trialType !== "manual" &&
    assessmentDateType?.filter(
      (item) =>
        formatFlightDate(item?.assessmentdate)?.split(" ")[0] ===
        formatFlightDate(selectedFlightDate)?.split(" ")[0]
    );

  let mergedManualCategories = {};
  let allCategories = [];
  if (trialType === "manual") {
    assessmentDateType?.forEach((item) => {
      Object.keys(item?.categories).forEach((category) => {
        if (!mergedManualCategories[category]) {
          mergedManualCategories[category] = [];
        }
        item?.categories[category].forEach((subItem) => {
          if (!mergedManualCategories[category].includes(subItem?.fullName)) {
            mergedManualCategories[category].push(subItem?.fullName);
          }
        });
      });
    });
  }
  if (trialType !== "manual") {
    flightAssessments.map((item) => {
      if (item?.assessmentType != "manual") {
        allCategories.push(item?.categories);
      } else {
        let mergedManualCategories = Object.keys(item?.categories).reduce((category, key) => {
          category[key] = item?.categories[key].map((item) => item?.fullName);
          return category;
        }, {});
        allCategories.push(mergedManualCategories);
      }
    });
  }

  let mergedCategories =
    trialType !== "manual" &&
    allCategories.reduce((category, curr) => {
      for (let key in curr) {
        if (category[key]) {
          category[key] = [...category[key], ...curr[key]];
        } else {
          category[key] = curr[key];
        }
      }
      return category;
    }, {});

  let categoryAssessments = trialType !== "manual" ? mergedCategories : mergedManualCategories;
  const [assessmentSearchError, setAssessmentSearchError] = useState(false);
  const [assessmentRadioButton, setAssessmentRadioButton] = useState("");
  const [applicationIssuesRadioButton, setApplicationIssuesRadioButton] = useState("");
  const [filteredAssessments, setFilteredAssessments] = useState(categoryAssessments);

  const applicationIssuesData = [
    "Drone Assessment",
    "Manual Assessment",
    "Treatment Lables",
    "Charts",
    "Environmental"
  ];

  useEffect(() => {
    if (buttonClicked === "Assessments") {
      if (
        assessmentRadioButton === "Request an additional Assessment" ||
        (assessmentRadioButton === "Report on Assessment issue" && checkBoxes?.length > 0)
      ) {
        setTextAreaDisabled(false);
      } else {
        setTextAreaDisabled(true);
      }
    }
    if (buttonClicked === "Application Issues") {
      if (applicationIssuesRadioButton === "") {
        setTextAreaDisabled(true);
      } else {
        setTextAreaDisabled(false);
      }
    }
  }, [checkBoxes, assessmentRadioButton, applicationIssuesRadioButton]);

  const handleNavigateToPlotEditor = (e) => {
    if (trialType !== "manual") {
      e.stopPropagation();
      let path = `/app/ploteditor?collection_id=${flightCollectionId}`;
      window.open(path, "_blank");
    }
  };

  const onChangeForFiltering = (e) => {
    setSearchText(e.target.value);
    if (categoryAssessments) {
      const filteredAssessmentData = Object.keys(categoryAssessments).reduce((category, type) => {
        const filterArray = categoryAssessments[type].filter((test) =>
          test?.toLowerCase().includes(e.target.value?.toLowerCase())
        );
        if (filterArray?.length > 0) {
          category[type] = filterArray;
        }
        return category;
      }, {});
      setFilteredAssessments(filteredAssessmentData);
      setAssessmentSearchError(Object.keys(filteredAssessmentData)?.length === 0);
    }
  };

  const fileUpload = async (selectedFiles) => {
    const uniqueFiles = [...new Set(selectedFiles?.map((file) => file?.name))].map((name) =>
      selectedFiles.find((file) => file?.name === name)
    );

    let allImages = [];
    uniqueFiles?.forEach((file) => {
      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: file?.name,
        Body: file,
        ContentType: file?.type,
        ServerSideEncryption: "AES256"
      };

      allImages.push(s3.upload(params).promise());
    });

    try {
      const results = await Promise.all(allImages);
      setSelectedFiles([]);
      const urls = results.map((result) => result?.Location);
      return urls;
    } catch (err) {
      console.log("err", err);
      return [];
    }
  };

  const sendReports = (feedbackType, details, sendFeedbackPayload, buttonClicked) => {
    sendReport(
      sendFeedbackPayload,
      (res) => {
        if (res?.status_code == 200) {
          setOpenTrialReportModal(false);
          setSuccessModal(true);
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let uploadedUrls = [];
    if (selectedFiles?.length > 0) {
      uploadedUrls = await fileUpload(selectedFiles);
    }

    let receiverEmail =
      buttonClicked === "Application Issues"
        ? "dlglobalinseasoninsights@syngenta.com"
        : "DDCOperationsTeam@syngenta.com";
    let details =
      buttonClicked === "Assessments" && assessmentRadioButton === "Report on Assessment issue"
        ? checkBoxes
        : buttonClicked === "Application Issues"
        ? [applicationIssuesRadioButton]
        : "";
    let feedbackType =
      buttonClicked === "Assessments"
        ? `assessment -${assessmentRadioButton}`
        : buttonClicked === "Application Issues"
        ? `Website Issues -${applicationIssuesRadioButton}`
        : buttonClicked;

    let saveFeedbackPayload = {
      trial_name: selectedOption,
      plot_number: 0,
      flight_date: trialType !== "manual" ? formatFlightDate(selectedFlightDate) : "",
      feedback_type: feedbackType,
      descriptions: description,
      details: details,
      created_by: userEmail,
      attachment_urls: uploadedUrls?.length > 0 ? uploadedUrls : []
    };

    if (buttonClicked === "Application Issues") {
      let sendFeedbackPayload = {
        receiver_email: "dlglobalinseasoninsights@syngenta.com",
        subject: "In-season Insights Feedback",
        body: "In-season Insights User's Feedback Details",
        page_url: deepLinkUrl,
        name: firstName + " " + lastName,
        email: userEmail,
        current_trial: selectedOption,
        flight_date: trialType !== "manual" ? selectedFlightDate : "",
        feedback_type: feedbackType,
        details: details,
        descriptions: description,
        row_no: "",
        submitted_datetime: DateFormatingForReportingModal(new Date())
      };
      sendReports(feedbackType, details, sendFeedbackPayload, buttonClicked);
    } else {
      saveReport(
        saveFeedbackPayload,
        (res) => {
          if (res?.row_no) {
            let sendFeedbackPayload = {
              receiver_email: receiverEmail,
              subject: "In-season Insights Feedback",
              body: "In-season Insights User's Feedback Details",
              page_url: deepLinkUrl,
              name: firstName + " " + lastName,
              email: userEmail,
              current_trial: selectedOption,
              flight_date: trialType !== "manual" ? selectedFlightDate : "",
              feedback_type: feedbackType,
              details: details,
              descriptions: description,
              row_no: res?.row_no,
              submitted_datetime: DateFormatingForReportingModal(new Date())
            };

            sendReports(feedbackType, details, sendFeedbackPayload, buttonClicked);
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  };

  const handleClose = () => {
    setOpenTrialReportModal(false);
  };

  const handleButtonClick = (value) => {
    setDescription("");
    setSearchText("");
    setCheckBoxes([]);
    setFilteredAssessments(categoryAssessments);
    setTextAreaDisabled(true);
    setButtonClicked(value);
    setAssessmentRadioButton("");
    setApplicationIssuesRadioButton("");

    if (value === "Trial Layout" || value === "Others" || value === "Application Issues") {
      setTextAreaDisabled(false);
    }
  };

  const handleAssessmentRadioButtonClick = (value) => {
    setAssessmentRadioButton(value);
  };

  const handleSearchClear = () => {
    setSearchText("");
    setFilteredAssessments(categoryAssessments);
  };

  const handleFileChange = (e) => {
    let files = Array.from(e.target.files);
    const filteredFiles = files.filter((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return !["exe", "dmg", "bat", "sh", "msi"].includes(fileExtension);
    });
    setSelectedFiles([...selectedFiles, ...filteredFiles]);
  };
  useEffect(() => {
    setSelectedFiles([]);
  }, [buttonClicked]);

  return (
    <>
      <Modal
        className={
          customMobilePortrait || customMobileLandscape
            ? "mobile-trial-report-modal"
            : "trial-report-modal"
        }
        show={openTrialReportModal}
        onHide={handleClose}
        backdrop="true"
        centered="true">
        <Modal.Header
          className={customMobilePortrait || customMobileLandscape ? "mobile-modal-header" : ""}>
          <Modal.Title className="mb-2">
            <span
              className={
                customMobilePortrait || customMobileLandscape
                  ? "mobile-report-title"
                  : "report-title"
              }>
              Report an issue
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div
            className={
              customMobileLandscape ? "landscape-report-modal-body" : "trial-report-modal-body"
            }>
            <div className={customMobilePortrait ? "ml-1 mb-2" : "ml-1 mb-3"}>
              <span className="report-modal-text">
                <strong>Trial </strong>
                <span className="font-weight-400">{selectedOption}</span>
              </span>

              {trialType !== "manual" && (
                <span className="ml-4 report-modal-text">
                  <strong>Flight Date</strong>{" "}
                  <span className="font-weight-400">
                    {formatFlightDate(selectedFlightDate)?.split(" ")[0]}
                  </span>
                </span>
              )}
            </div>
            <div className="ml-1 mb-3">
              {buttonClicked !== "Trial Layout" &&
                buttonClicked !== "Application Issues" &&
                buttonClicked !== "Assessments" &&
                buttonClicked !== "Others" && (
                  <span className="report-modal-text">
                    <InfoCircleFill fill="#FFB71E" height={20} width={20} /> &nbsp;&nbsp;Select one
                    option to enable description
                  </span>
                )}
            </div>

            <div className="mb-3 mt-3 d-flex all-buttons">
              <Button
                className="report-modal-buttons"
                onClick={() => handleButtonClick("Trial Layout")}
                style={{
                  backgroundColor: buttonClicked === "Trial Layout" ? "#E08330" : "#EDEDED",
                  color: buttonClicked === "Trial Layout" ? "#FFFFFF" : "#0D1A40"
                }}>
                Trial Layout
              </Button>
              <Button
                onClick={() => handleButtonClick("Assessments")}
                className="ml-3 report-modal-buttons"
                style={{
                  backgroundColor: buttonClicked === "Assessments" ? "#E08330" : "#EDEDED",
                  color: buttonClicked === "Assessments" ? "#FFFFFF" : "#0D1A40"
                }}>
                Assessments
              </Button>
              <Button
                onClick={() => handleButtonClick("Application Issues")}
                className="ml-3 report-modal-buttons"
                style={{
                  backgroundColor: buttonClicked === "Application Issues" ? "#E08330" : "#EDEDED",
                  color: buttonClicked === "Application Issues" ? "#FFFFFF" : "#0D1A40"
                }}>
                Website Issues
              </Button>
              <Button
                onClick={() => handleButtonClick("Others")}
                className="ml-3 report-modal-buttons"
                style={{
                  backgroundColor: buttonClicked === "Others" ? "#E08330" : "#EDEDED",
                  color: buttonClicked === "Others" ? "#FFFFFF" : "#0D1A40"
                }}>
                Others
              </Button>
            </div>
            {buttonClicked === "Trial Layout" && (
              <div className="ml-2 mr-2 mb-3 mt-3">
                <span className="font-family-Helvetica font-size-small font-weight-400 deep-cove-color">
                  To request a new trial layout, please submit it via the
                  {customMobilePortrait || customMobileLandscape || customTabletDimensions() ? (
                    " Plot Editor "
                  ) : (
                    <span
                      onClick={handleNavigateToPlotEditor}
                      style={{
                        color: trialType !== "manual" ? "#255AF6" : "#0D1A40",
                        cursor: trialType !== "manual" ? "pointer" : "default"
                      }}>
                      &nbsp;Plot Editor&nbsp;
                    </span>
                  )}
                  tool.
                </span>
              </div>
            )}
            <div className="ml-2 mb-3 mt-3 mr-2">
              <input
                type="file"
                id="fileInput"
                data-testid="fileInput"
                className="file-display"
                multiple
                onChange={handleFileChange}
                disabled={!buttonClicked}
                accept="*/*"
              />
              <label
                htmlFor="fileInput"
                className={buttonClicked ? "cursor-pointer" : ""}
                style={{ opacity: buttonClicked ? 1 : 0.6 }}>
                <span className="attach-file-text">
                  Attach Files <Paperclip width={16} height={16} />
                  <span className="ml-1 optional-text">(Optional)</span>
                </span>
              </label>
              {selectedFiles?.length > 0 ? (
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-wrap">
                    {selectedFiles?.map((file, index) => (
                      <div
                        key={index}
                        className="file-name-pill d-flex align-items-center justify-content-between mr-2 mt-1">
                        <span className="mr-2 file-name">{file.name}</span>
                        <X
                          data-testid="remove-file"
                          className="cursor-pointer"
                          onClick={() =>
                            setSelectedFiles(selectedFiles?.filter((_, i) => i !== index))
                          }
                        />
                      </div>
                    ))}
                  </div>
                  {selectedFiles?.length > 1 && (
                    <div className="ml-3 remove-all-text" onClick={() => setSelectedFiles([])}>
                      REMOVE ALL
                    </div>
                  )}
                </div>
              ) : (
                <div className="description-text" style={{ opacity: buttonClicked ? 1 : 0.6 }}>
                  Attach screenshots, screen recordings or other relevant files illustrated the
                  issue. Visual aid can help the team to understand the issue more effectively.
                </div>
              )}
            </div>

            {buttonClicked === "Assessments" && (
              <div className="mb-3">
                <div className="ml-1 mb-1">
                  <span className="custom-trail-radio-button mr-1">
                    <input
                      type="radio"
                      data-testid="requestAnAssessment"
                      onClick={() =>
                        handleAssessmentRadioButtonClick("Request an additional Assessment")
                      }
                      checked={assessmentRadioButton === "Request an additional Assessment"}
                    />
                  </span>
                  <span className="checkmark font-size-small font-weight-400 font-family-Helvetica deep-cove-color">
                    Request an additional Assessment
                  </span>
                </div>
                <div className="ml-1">
                  <span className="custom-trail-radio-button mr-1">
                    <input
                      data-testId="reportAnIssue"
                      type="radio"
                      onClick={() => handleAssessmentRadioButtonClick("Report on Assessment issue")}
                      checked={assessmentRadioButton === "Report on Assessment issue"}
                    />
                  </span>
                  <span className="font-size-small font-weight-400 font-family-Helvetica deep-cove-color">
                    Report on Assessment issue
                  </span>
                </div>
              </div>
            )}

            {buttonClicked === "Assessments" &&
              assessmentRadioButton === "Report on Assessment issue" && (
                <div
                  className={
                    customMobilePortrait
                      ? "mb-3 mobile-assessments-input-group"
                      : "mb-3 assessments-input-group"
                  }>
                  <div className="d-flex">
                    <InputGroup>
                      <FormControl
                        type="text"
                        placeholder="Search for ratings"
                        className="report-search"
                        value={searchText}
                        onChange={onChangeForFiltering}
                      />
                      {searchText ? (
                        <X
                          data-testid="trial-report-clear-search"
                          onClick={handleSearchClear}
                          className="trial-report-assessment-search-icon"
                          width={15}
                          height={15}
                        />
                      ) : (
                        <Search
                          data-testid="trial-report-search-icon"
                          onClick={() => {}}
                          className="trial-report-assessment-search-icon"
                          width={15}
                          height={15}
                        />
                      )}
                    </InputGroup>
                  </div>

                  <div className={customMobilePortrait ? "mt-3" : "ml-3 mt-3"}>
                    {checkBoxes?.length === 0 && !assessmentSearchError && (
                      <span className="report-modal-text">
                        <InfoCircleFill fill="#FFB71E" height={20} width={20} /> &nbsp;&nbsp;Please
                        select at least one assessment to enable description
                      </span>
                    )}
                  </div>

                  <div className="text-right pr-3 pt-2 mobileClearAll">
                    {!assessmentSearchError && (
                      <span
                        className="trial-ass-clear-all-position"
                        style={{ float: "unset", fontSize: "14px" }}
                        onClick={() => setCheckBoxes([])}>
                        DESELECT ALL
                      </span>
                    )}
                  </div>
                  {!assessmentSearchError ? (
                    <div className="assessments-div">
                      {filteredAssessments &&
                        Object.keys(filteredAssessments)
                          .sort((a, b) => {
                            if (a?.toLowerCase() < b?.toLowerCase()) {
                              return -1;
                            }
                            if (a?.toLowerCase() > b?.toLowerCase()) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((type) => {
                            const filterArray = filteredAssessments[type];

                            return filterArray?.length > 0 ? (
                              <div className={"assessments-seperation"}>
                                <div className="d-flex justify-content-between assessment-group pr-3">
                                  <span className="trial-report-drone-manual-img">{type}</span>
                                </div>
                                {filterArray?.map((title) => {
                                  const typeCheck = title;

                                  return (
                                    <div
                                      className="d-flex justify-content-between align-items-center p-1"
                                      key={typeCheck}>
                                      <div>
                                        <label>
                                          <input
                                            type="checkbox"
                                            name="options"
                                            className="assessment-input"
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setCheckBoxes([...checkBoxes, typeCheck]);
                                              } else {
                                                setCheckBoxes(
                                                  checkBoxes.filter((item) => item !== typeCheck)
                                                );
                                              }
                                            }}
                                            checked={checkBoxes.includes(typeCheck)}
                                          />
                                          <span className="report-assessment-names">
                                            {typeCheck?.includes("v:")
                                              ? typeCheck?.split("v:")[0]
                                              : typeCheck}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : null;
                          })}
                    </div>
                  ) : (
                    <div className="d-flex ml-3 justify-content-center align-items-center mb-3">
                      <span className="report-modal-no-results-text">No results found</span>
                    </div>
                  )}
                </div>
              )}

            {buttonClicked === "Application Issues" && (
              <div>
                {applicationIssuesData?.map((item) => (
                  <div className="ml-1 mb-1" key="">
                    <span className="custom-trail-radio-button mr-1">
                      <input
                        data-testId={
                          item === "Drone Assessment"
                            ? "drone-assessment-radio-button"
                            : item === "Manual Assessment"
                            ? "manual-assessment-radio-btn"
                            : item === "Treatment Lables"
                            ? "treatment-lables-radio-btn"
                            : item === "Charts"
                            ? "charts-radio-btn"
                            : "environmental-radio-btn"
                        }
                        type="radio"
                        onClick={() => setApplicationIssuesRadioButton(item)}
                        checked={applicationIssuesRadioButton === item}
                      />
                    </span>
                    <span className="font-size-small font-weight-400 font-family-Helvetica deep-cove-color">
                      {item}
                    </span>
                  </div>
                ))}
              </div>
            )}

            <div
              className={
                customMobilePortrait || customMobileLandscape
                  ? "ml-2 mt-3 mobile-description-textarea"
                  : "ml-2 mt-3 trial-description-textarea"
              }
              style={{
                opacity: textAreaDisabled ? 0.5 : 1
              }}>
              <label className="mb-2 font-family-Helvetica font-size-small">
                <strong> Description</strong>
              </label>
              <textarea
                data-testid="description-textarea"
                rows="4"
                cols="45"
                placeholder="Provide your details here..."
                className="form-control"
                value={description}
                disabled={textAreaDisabled === true}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>

          <div
            className={
              customMobilePortrait
                ? "d-flex justify-content-center mobile-trial-report-modal-footer"
                : "d-flex justify-content-center trial-report-modal-footer"
            }>
            <button onClick={handleClose} className="trial-report-modal-cancel">
              <span className="deep-cove-color">CANCEL</span>
            </button>

            <button
              onClick={handleSubmit}
              className="report-modal-submit"
              style={{
                opacity: description === "" ? 0.3 : 1
              }}
              disabled={description === ""}>
              <span>SUBMIT</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="success-modal"
        show={successModal}
        // onHide={() => setSuccessModal(false)}
        centered>
        <Modal.Body
          className={customMobilePortrait ? "mobile-success-modal-body" : "success-modal-body"}>
          <div className="d-flex flex-column justify-content-center align-items-center py-5">
            <CheckCircleFill color="#2EB800" height={48} width={48} />
            <div className="pt-3">
              <span
                className={
                  customMobilePortrait
                    ? "font-size-large font-weight-400"
                    : "font-24 deep-cove-color"
                }>
                Issue has been reported successfully
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            onClick={() => {
              setSuccessModal(false);
            }}
            className="success-modal-ok">
            <span>OK</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

TrialReportModal.propTypes = {
  openTrialReportModal: propTypes.any,
  setOpenTrialReportModal: propTypes.any,
  selectedOption: propTypes.any,
  selectedFlightDate: propTypes.any,
  assessmentDateType: propTypes.any,
  successModal: propTypes.any,
  setSuccessModal: propTypes.any,
  trialType: propTypes.any,
  flightCollectionId: propTypes.any,
  customMobilePortrait: propTypes.any,
  customMobileLandscape: propTypes.any,
  deepLinkUrl: propTypes.any
};
