/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { useMsal } from "@azure/msal-react";
import { loginSyt, flightChartApi } from "../services/API";
import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import propTypes from "prop-types";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement
} from "chart.js";
import { colors } from "../services/Common";
import Plot from "react-plotly.js";
import { useCookies } from "react-cookie";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement
);
export default function LineChart({
  measurements,
  assessment,
  rightDrawer,
  trailname,
  selectedValue,
  collectionId
}) {
  const [cookies] = useCookies(["idToken"]);
  const idToken = cookies?.idToken;
  const [flightData, setFlightData] = useState([]);

  useEffect(() => {
    const trail = measurements.filter((item) => item?.name === trailname);
    loginSyt(idToken, (res) => {
      if (res == 200) {
        flightChartApi(
          {
            assessment: assessment?.vector,
            trial_id: trail[0]?.trial_id,
            collection_id: collectionId,
            subplot_id: selectedValue === "All subplots" || !selectedValue ? "" : selectedValue
          },
          (res) => {
            if (res?.flightChartData?.length > 0) {
              setFlightData(res);
            }
          },
          (err) => {
            console.log("err", err);
          }
        );
      } else {
        const { instance } = useMsal();
        instance.logoutRedirect({
          postLogoutRedirectUri: "/app",
          mainWindowRedirectUri: "/app" // redirects the top level app after logout
        });
        sessionStorage.removeItem("msal.interaction.status");
      }
    });
    // }
  }, [assessment?.vector, trailname, selectedValue]);
  function getRandomColors() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  const dateArray = flightData?.flightChartData;
  console.log(flightData);
  const allDates = new Set();
  const data = {
    labels: [], //array to store the dates
    datasets: [] // array to store the treatmentNumbers
  };
  //Create new object with treatment num and mean details
  const treatments = dateArray?.map((item) => ({
    treatmentNum: item.treatment_num,
    meanDetails: item.mean_details
  }));

  treatments?.forEach((item) => {
    item?.meanDetails.forEach((detail) => {
      allDates.add(detail?.date);
    });
  });

  treatments?.forEach((flight) => {
    const flightDates = new Set(flight?.meanDetails.map((item) => item?.date));
    allDates.forEach((date) => {
      if (!flightDates.has(date)) {
        flight.meanDetails.push({ date, mean_value: 0 });
      }
    });
  });

  treatments?.forEach((flight) => {
    flight?.meanDetails?.sort((a, b) => new Date(a?.date) - new Date(b?.date));
  });

  //sorted by treatment num
  const sortedData = treatments?.sort((a, b) => a?.treatmentNum - b?.treatmentNum);
  sortedData?.sort()?.forEach((treatment) => {
    const dataset = {
      label: `${treatment.treatmentNum}`,
      data: [],
      fill: false,
      borderColor:
        treatment?.treatmentNum <= 30
          ? colors[treatment.treatmentNum]
          : colors[treatment.treatmentNum % 30],
      pointBorderColor:
        treatment?.treatmentNum <= 30
          ? colors[treatment.treatmentNum]
          : colors[treatment.treatmentNum % 30],
      pointBackgroundColor:
        treatment?.treatmentNum <= 30
          ? colors[treatment.treatmentNum]
          : colors[treatment.treatmentNum % 30]
    };
    //On each meandetails looped and if created unique array of dates and array of mean details with respective dates

    treatment?.meanDetails
      .sort(function (a, b) {
        return new Date(a) - new Date(b);
      })
      .forEach((detail) => {
        const index = data?.labels.findIndex((object) => object === detail?.date);
        if (index === -1) {
          data?.labels.push(detail?.date);
        }
        dataset?.data.push(detail?.mean_value);
      });
    data?.datasets.push(dataset);
  });

  data?.labels.sort(function (a, b) {
    return new Date(a) - new Date(b);
  });

  const PlotData = treatments
    ?.sort((a, b) => new Date(a?.date) - new Date(b?.date))
    ?.map((item) => ({
      x: item?.meanDetails.map((details) => details?.date),
      y: item?.meanDetails.map((details) => parseFloat(details?.mean_value).toFixed(2)),
      type: "scatter",
      mode: "lines+markers",
      name: item?.treatmentNum,
      line: {
        width: 2.5
      },
      marker: {
        size: 8,
        color:
          item?.treatmentNum <= 30 ? colors[item?.treatmentNum] : colors[item?.treatmentNum % 30]
      }
    }));

  return (
    <>
      {data?.labels.length > 1 && (
        <>
          <Typography variant="subtitle" style={{ display: "block", textAlign: "center" }}>
            {flightData?.name?.split("v:")[0]}
          </Typography>
          <div
            style={{
              height: window.innerHeight < 440 ? "130px" : "280px",
              paddingBottom: "10px"
            }}>
            <Plot
              style={{ cursor: "pointer" }}
              data={PlotData}
              layout={{
                autosize: true,
                margin: {
                  t: 30,
                  b: window.innerHeight < 440 ? 350 : !rightDrawer ? 215 : 220,
                  l: 40,
                  r: 40
                },
                showlegend: true,
                legend: {
                  usePointStyle: true
                },
                xaxis: {
                  type: "date",
                  linecolor: "#E8E8E8",
                  tickvals: data?.labels?.sort((a, b) => new Date(a) - new Date(b)),
                  tickformat: "%Y-%m-%d",
                  zeroline: false,
                  tickfont: {
                    size: window.innerWidth < 440 ? 8 : 10
                  },
                  title: {
                    text: window.innerHeight > 440 ? "Dates" : ""
                  },
                  titlefont: {
                    family: "Helvetica",
                    size: 12
                  }
                },
                yaxis: {
                  type: "linear",
                  tickfont: {
                    size: window.innerWidth < 440 ? 8 : 10
                  },
                  title: {
                    text: window.innerHeight > 440 ? "Assesment Values" : ""
                  },
                  titlefont: {
                    family: "Helvetica",
                    size: 12
                  }
                },
                responsive: true
              }}
              config={{
                staticPlot: false,
                displaylogo: false,
                responsive: true,
                animation: false,
                modeBarButtonsToRemove: [
                  "pan2d",
                  "select2d",
                  "autoScale2d",
                  "hoverClosestCartesian",
                  "zoom2d",
                  "lasso2d"
                ]
              }}
            />
          </div>
        </>
      )}
      {data?.labels.length === 1 && (
        <>
          <Typography
            variant="title"
            style={{ display: "block", textAlign: "center", marginTop: "20px" }}>
            <strong>Treatment information is unavailable as it has only one date</strong>
          </Typography>
        </>
      )}
    </>
  );
}

LineChart.propTypes = {
  assessment: propTypes.any,
  trailname: propTypes.any,
  measurements: propTypes.any,
  rightDrawer: propTypes.any,
  selectedValue: propTypes.any,
  collectionId: propTypes.any
};
